import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../constants/constants';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
  ) { }


  login(data): Observable<any>{
    return this.http.post( Constants.apiUrl + 'User/login', data);
  }

  getUserList(): Observable<any>{
    debugger
    return this.http.get( Constants.apiUrl + 'User/getUsers');
    }
    GetContactListById(id?): Observable<any> {
      let url = id == undefined || null ?  Constants.apiUrl + 'Home/GetContact' :  Constants.apiUrl +'Home/GetContact?id=' + id
        return this.http.get(url);
    }
    GetContactList(obj): Observable<any> {
      return this.http.post( Constants.apiUrl + 'Home/GetContactListSP',obj);
  }
  GetContactListbyLetter(obj): Observable<any> {
    return this.http.post( Constants.apiUrl + 'Home/GetContactListByLetter',obj);
}
    AddContact(data?): Observable<any> {
        return this.http.post( Constants.apiUrl +'Home/AddContact', data);
    }
    DeleteContact(id?): Observable<any> {
        return this.http.get( Constants.apiUrl +'Home/DeleteContact?id=' + id);
    }
    GetJobList(jobno?): Observable<any> {
      let url = jobno == undefined || null ?  Constants.apiUrl + 'Home/GetJoblist' :  Constants.apiUrl +'Home/GetJoblist?jobno=' + jobno
      return this.http.get(url);
  }
  GetJobNumbersListSP(obj): Observable<any> {
    return this.http.post( Constants.apiUrl +'Home/GetJobNumbersListSP' , obj);
}
  GetJobListById(jobno): Observable<any> {
    let url =  Constants.apiUrl +'Home/GetJoblistById?jobno=' + jobno
    return this.http.get(url);
}
GetJobDepartmentsListById(jobno): Observable<any> {
  let url =  Constants.apiUrl +'Home/GetJobDepartmentsListById?jobno=' + jobno
  return this.http.get(url);
}
    DeleteJob(jobno?): Observable<any> {
    return this.http.get( Constants.apiUrl +'Home/DeleteJob?jobNo=' + jobno);
  }
  AddJob(parameter): Observable<any> {
    return this.http.post( Constants.apiUrl +'Home/CreateJob', parameter);
}
GetJobDistributionListSPById(obj): Observable<any> {
  return this.http.post( Constants.apiUrl +'Home/GetJobDistributionListSPById' , obj);
}

    getUserDetail(id): Observable<any>{
      return  this.http.get( Constants.apiUrl + 'User/getUserById?UserId='+id);
    }
    addEditUser(user): Observable<any>{
      return this.http.post( Constants.apiUrl + 'User/addUser', user);
    }
    GetLastJobNo(): Observable<any> {
      return this.http.get( Constants.apiUrl +'Home/GetNextJobNo');
  }
  AddfileSave(obj): Observable<any> {
    return this.http.post( Constants.apiUrl +'Home/CreateDocumentRegister', obj);
}
GetDocsRegisterByJobNo(JobNo): Observable<any> {
  return this.http.get( Constants.apiUrl +'Home/GetDocumentRegisterByJobNo?JobNo='+JobNo);
}
GetDocumentsByRegisterID(DocRegId): Observable<any> {
  return this.http.get( Constants.apiUrl +'Home/GetDocumentsByRegisterID?DocRegId='+DocRegId);
}
UpdateDocument(obj): Observable<any> {
  return this.http.post( Constants.apiUrl +'Home/UpdateDocumentRegister', obj);
}
AddLinkSave(data): Observable<any> {
  return this.http.post( Constants.apiUrl +'Home/InsertLink', data);
}
DocNewfileInsert(data): Observable<any> {
  return this.http.post( Constants.apiUrl +'Home/InesertNewfile', data);
}
ModifyFile(data): Observable<any> {
  return this.http.post( Constants.apiUrl +'Home/DocumentsModifyFile', data);
}
UploadFile(data): Observable<any> {
  return this.http.post( Constants.apiUrl +'Home/UploadFile', data);
}
DeleteDocument(data): Observable<any> {
  return this.http.post( Constants.apiUrl +'Home/DeleteDocument', data);
}
GetSortedDocumentsByRegisterID(data): Observable<any> {
  return this.http.post( Constants.apiUrl +'Home/GetSortedDocumentsByRegisterID',data);
}
GetImageUrl(filename): Observable<any>{
  return  this.http.get( Constants.apiUrl +'Home/getimageurl?filename='+filename);
}
SaveConfigParam(obj): Observable<any> {
  return this.http.get( Constants.apiUrl +'Home/SaveConfigParam?obj='+obj);
}
GetConfigParameter(): Observable<any> {
  return this.http.get( Constants.apiUrl +'Home/GetConfigParameter');
}
GetDocumentDepartmentList(DocId): Observable<any> {
  return this.http.get( Constants.apiUrl +'Home/GetDocumentDepartmentList?DocId='+DocId);
}
GetDepartmentActivities(DocumentDepartmentId): Observable<any> {
  return this.http.get( Constants.apiUrl +'Home/GetDepartmentActivity?DocumentDepartmentId='+DocumentDepartmentId);
}
SaveActivity(data): Observable<any> {
  return this.http.post( Constants.apiUrl +'Home/AddDepartmentActivity', data);
}
checkFileExists(data): Observable<any> {
  return this.http.post( Constants.apiUrl +'Home/CheckFileExist', data);
}
GetImageUrlCustom(filename): Observable<any>{
  return  this.http.get( Constants.apiUrl +'User/getimageurl?filename='+filename);
}
}
