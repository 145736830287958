import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UtilsService } from '../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {


  constructor(public utilsService: UtilsService,public router: Router) { }

  canActivate() {
    // if(!this.utilsService.IsUserLogin()){
    //     this.router.navigateByUrl('/login');
    //     return false;
    // }
    //   return true;
      if (this.utilsService.IsUserLogin()) {
        return true;
      }  else {
        this.router.navigate(['/login']);
      }
  }

}
