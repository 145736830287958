import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Status } from 'src/app/models/ResponseModel';
import { CookiesService } from 'src/app/services/cookies.service';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.css']
})
export class AddContactComponent implements OnInit {
  AddContactFormGroup: FormGroup;
  contactid: any;
  contacts: any=[];
  pageNo: any;
  UserId=this.cookiesService.getCookie('UserId');
  constructor(private cookiesService: CookiesService,private toastr: ToastrService,private activeRoute:ActivatedRoute,private formBuilder: FormBuilder,private UserService: UserService,private router:Router) { }

  ngOnInit() {
    this.AddContactForm();


    this.contactid = this.activeRoute.snapshot.paramMap.get("id")
    this.pageNo = this.activeRoute.snapshot.paramMap.get("page")
    if(this.pageNo==null || this.pageNo==0){
      this.pageNo=1;
    }
    if(this.contactid!=null && this.contactid != 0)
    {
      this.GetcontactsById(this.contactid);
    }
  }
  AddContactForm(): void {
    this.AddContactFormGroup = this.formBuilder.group({
      ContactId:[null],
      ContactTitle: [null, Validators.required],
      Firstname: [null],
      LastName: [null],
      CompanyName: [null],
      Address1: [null],
      Address2: [null],
      Address3: [null],
      Address4: [null],
      PostCode: [null],
      Phone1_Label: [null],
      Phone1_Description: [null],
      Phone2_Label: [null],
      Phone2_Description: [null],
      Email1_Label: [null],
      Email1_Description: [null],
      Email2_Label: [null],
      Email2_Description: [null],
      Website: [null],
      Field1_Label: [null],
      Field1_Description: [null, ],
      Field2_Label: [null],
      Field2_Description: [null],
      Field3_Label: [null],
      Field3_Description: [null],
    })
  }

  GetPrevContactDetails(){
    debugger
    var prevalue= (parseInt(this.AddContactFormGroup.controls.ContactId.value)-1);
     this.GetcontactsById((prevalue))
   }
   GetNextContactDetails(){
     debugger
     var nextvalue= (parseInt(this.AddContactFormGroup.controls.ContactId.value)+1);
     this.GetcontactsById((nextvalue))
   }

  GetcontactsById(contactid) {
    this.AddContactFormGroup.get('ContactId').setValue(contactid)
    this.UserService.GetContactListById(contactid).subscribe(resp => {
      if (resp.status == Status.Success) {
        debugger
        if(resp.data.length>0){
        this.contacts=resp.data;
        this.AddContactFormGroup.get('ContactId').setValue(contactid)
        this.AddContactFormGroup.get('ContactTitle').setValue(this.contacts[0].contactTitle)
        this.AddContactFormGroup.get('Firstname').setValue(this.contacts[0].firstName)
        this.AddContactFormGroup.get('LastName').setValue(this.contacts[0].lastName)
        this.AddContactFormGroup.get('CompanyName').setValue(this.contacts[0].companyName)
        this.AddContactFormGroup.get('Address1').setValue(this.contacts[0].address1)
        this.AddContactFormGroup.get('Address2').setValue(this.contacts[0].address2)
        this.AddContactFormGroup.get('Address3').setValue(this.contacts[0].address3)
        this.AddContactFormGroup.get('Address4').setValue(this.contacts[0].address4)
        this.AddContactFormGroup.get('PostCode').setValue(this.contacts[0].postCode)
        this.AddContactFormGroup.get('Phone1_Label').setValue(this.contacts[0].phone1_Label)
        this.AddContactFormGroup.get('Phone1_Description').setValue(this.contacts[0].phone1_Description)
        this.AddContactFormGroup.get('Phone2_Label').setValue(this.contacts[0].phone2_Label)
        this.AddContactFormGroup.get('Phone2_Description').setValue(this.contacts[0].phone2_Description)
        this.AddContactFormGroup.get('Email1_Label').setValue(this.contacts[0].email1_Label)
        this.AddContactFormGroup.get('Email1_Description').setValue(this.contacts[0].email1_Description)
        this.AddContactFormGroup.get('Email2_Label').setValue(this.contacts[0].email2_Label)
        this.AddContactFormGroup.get('Email2_Description').setValue(this.contacts[0].email2_Description)
        this.AddContactFormGroup.get('Website').setValue(this.contacts[0].website)
        this.AddContactFormGroup.get('Field1_Label').setValue(this.contacts[0].field1_Label)
        this.AddContactFormGroup.get('Field1_Description').setValue(this.contacts[0].field1_Description)
        this.AddContactFormGroup.get('Field2_Label').setValue(this.contacts[0].field2_Label)
        this.AddContactFormGroup.get('Field2_Description').setValue(this.contacts[0].field2_Description)
        this.AddContactFormGroup.get('Field3_Label').setValue(this.contacts[0].field3_Label)
        this.AddContactFormGroup.get('Field3_Description').setValue(this.contacts[0].field3_Description)
        }
        else{
          this.toastr.warning("no record found");
        }
      }
    });
  }
  AddContact() {
    debugger
      let value = this.AddContactFormGroup.value;
      value.userId=this.UserId;
      if(this.contactid!=null && this.contactid!=undefined && this.contactid)
      {
        value.id=this.contactid;
      }
        this.UserService.AddContact(value).subscribe(resp => {
          if (resp.data == true) {
            this.toastr.success(resp.message);
            this.AddContactFormGroup.reset();
            this.router.navigateByUrl("/contacts/"+this.pageNo)
          }
          else {
            //this.toastr.error(resp.message);
          }
        });
  }

  Back()
  {
    this.router.navigateByUrl("/contacts/"+this.pageNo)
  }
}
