export class UserInfo{
  static UserName: string;
  static Role: string;
  static MemberSince;

  public  Id ;
  public  RoleId ;
  public  firstName ;
  public  lastName ;
  public  userName ;
  public  securityCode ;
  public  dateCreated ;
  public  dateModified ;
  public  isActive ;
  public  updatedBy ;
}
