import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { CookiesService } from 'src/app/services/cookies.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  user;
  constructor(
    private spinner: NgxSpinnerService,
    private cookiesService: CookiesService,
    private userService: UserService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.getUserInfo();
  }


  getUserInfo(){
    let userId = this.cookiesService.getCookie('UserId');
    console.log('aaaa', userId)

    this.spinner.show();
    this.userService.getUserDetail(userId).subscribe(res=>{
      console.log('reponse from get user info api', res)
      this.user = res.data;
      this.spinner.hide();
    })
  }

  editUser(){
    let userId = this.cookiesService.getCookie('UserId');
    this.user['UpdatedBy'] = userId;
    ;
    this.spinner.show();
    this.userService.addEditUser(this.user).subscribe(res=>{
      this.toastr.success('Profile updated successfully');
      this.spinner.hide();
      console.log('reposne from addedit user api', res);
    })
  }
}
