import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { NgbActiveModal,NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/public/login/login.component';
import { SideNavComponent } from './components/shared/side-nav/side-nav.component';
import { TopNavComponent } from './components/shared/top-nav/top-nav.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { DashboardComponent } from './components/shared/dashboard/dashboard.component';
import { JobListComponent } from './components/private/job-list/job-list.component';
import { AddNewJobComponent } from './components/private/add-new-job/add-new-job.component';
import { HeaderComponent } from "./components/shared/header/header.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CookieService } from "angular2-cookie";
import { UserlistComponent } from "./components/private/userlist/userlist.component";
import { ContactDetailsComponent } from "./components/private/contact-details/contact-details.component";
import { AddContactComponent } from "./components/private/add-contact/add-contact.component";
import { ProfileComponent } from "./components/private/profile/profile.component";
import { AddEditUserComponent } from "./components/private/add-edit-user/add-edit-user.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { JWTIntereptorService } from './middleware/httpinterceptor';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DocumentRegisterComponent } from './components/private/document-register/document-register.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { PaginationComponent } from './components/shared/pagination/pagination.component';
import { DocumentListComponent } from './components/public/document-list/document-list.component';
import { DataTablesModule } from 'angular-datatables';
import { ConfigParametersComponent } from './components/private/config-parameters/config-parameters.component';
import { ProgressFormComponent } from './components/private/progress-form/progress-form.component';
import { DefaultComponent } from './components/public/default/default.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    SideNavComponent,
    TopNavComponent,
    DashboardComponent,
    HeaderComponent,
    ContactDetailsComponent,
    AddContactComponent,
    UserlistComponent,
    JobListComponent,
    AddNewJobComponent,
    DocumentListComponent,
    ProfileComponent,
    AddEditUserComponent,
    DocumentRegisterComponent,
    PaginationComponent,
    ConfigParametersComponent,
    ProgressFormComponent,
    DefaultComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
     HttpClientModule,
     ModalModule.forRoot(),
    // NgbModule  
    BsDatepickerModule.forRoot(),
    NgxSpinnerModule,
    DataTablesModule,
    ToastrModule.forRoot(
      {
        // toastClass: 'toast toast-bootstrap-compatibility-fix',
        preventDuplicates: true,
        positionClass:'toast-top-right',
        closeButton: true,
        progressAnimation:'increasing',

      }
    ) ,
  ],
  entryComponents:[
  ],
  exports:[
    
  ],

  providers: [
    ToastrService,
    CookieService,
    DatePipe,
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: JWTIntereptorService, multi: true }  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
