import { Injectable } from '@angular/core';
import { CookieService, CookieOptions, CookieOptionsArgs } from 'angular2-cookie/core'

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  today = new Date();

  dur = new Date(this.today.setMonth(this.today.getMonth()+1));

   cookieOptions: CookieOptionsArgs ={expires : this.dur};

  constructor(private cookieService: CookieService) { }

  setCookie(key, value){
    this.cookieService.put(key, value, this.cookieOptions);
  }

  getCookie(key: string) {
    return this.cookieService.get(key);
  }

  deleteCookies(){
    this.cookieService.removeAll();
    this.deleteToken();
  }

  deleteToken(){
    localStorage.clear();
  }

}
