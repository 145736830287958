import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Status } from 'src/app/models/ResponseModel';

@Component({
  selector: 'app-config-parameters',
  templateUrl: './config-parameters.component.html',
  styleUrls: ['./config-parameters.component.css']
})
export class ConfigParametersComponent implements OnInit {
  ConfigurationFormGroup: FormGroup
  configParam: any;

  constructor(private formBuilder: FormBuilder,private toastr: ToastrService,private UserService: UserService) { }

  ngOnInit() {
    this.ConfigurationForm();
    this.GetConfig();
  }
  ConfigurationForm(): void {
    this.ConfigurationFormGroup = this.formBuilder.group({
      PublicFooterMsg: ['',Validators.required],
    })
  }
  GetConfig(){
    this.UserService.GetConfigParameter().subscribe(resp => {
      if (resp.status == Status.Success) {
        this.configParam=resp.data;
        this.ConfigurationFormGroup.get('PublicFooterMsg').setValue(this.configParam.publicFootermsg)
      }
      else {
        this.toastr.error(resp.message);
      }
    });
  }
  Save()
  {
    debugger
      let value = this.ConfigurationFormGroup.get('PublicFooterMsg').value;
      this.UserService.SaveConfigParam(value).subscribe(resp => {
        if (resp.status == Status.Success) {
          this.toastr.success(resp.message);
        }
        else {
          this.toastr.error(resp.message);
        }
      });
  }

}
