import { Component, OnInit } from '@angular/core';
import { CookiesService } from 'src/app/services/cookies.service';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

  isAdmin: boolean = false;
  userName;

  constructor(
    private cookieService: CookiesService
  ) { }

  ngOnInit() {

    this.userName = this.cookieService.getCookie('UserName');
    let role = this.cookieService.getCookie('Role');
    if(role === 'Admin') this.isAdmin = true;
  }


}
