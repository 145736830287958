import { Constants } from 'src/app/constants/constants';
import { Component, OnInit, ViewChild } from "@angular/core";
import { DocumentsService } from "src/app/services/documents.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Document } from "src/app/models/Document";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UserService } from 'src/app/services/user.service';
import { Status } from 'src/app/models/ResponseModel';
import { DatePipe } from '@angular/common';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';

@Component({
selector: "app-document-list",
templateUrl: "./document-list.component.html",
styleUrls: ["./document-list.component.css"],
})
export class DocumentListComponent implements OnInit {
dtOption: any;

public documentList = new Array<Document>();

image: string;
distList: string;
distListNo;
headerColor;
  DistType: any;



constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private documentsService: DocumentsService,
    private spinner: NgxSpinnerService,
    private UserService: UserService,
    private datePipe:DatePipe
    ) {
this.route.queryParams.subscribe((params) => {

this.code = params["code"];

this.documentsService
.GetJobDetailForLink(encodeURIComponent(this.code))
.subscribe((res) => {
if (res.data) {
  debugger
this.jobNo = res.data.jobNo;
this.jobTitle = res.data.jobTitle;
this.entryDate = res.data.entryDate;
this.LastModified=res.data.lastModified;
this.msgFrmDash=res.data.publicFooterMsg;
this.DistType=res.data.distType;
//this.image = res.data.imageFile;

debugger;
if (res.data.imageFile != null && res.data.imageFile != '') {
    this.UserService.GetImageUrlCustom(res.data.imageFile).subscribe(resp => {
      if (resp.status == Status.Success) {
        this.image = resp.data;
      }
    })
  }


this.getDocumentsList(this.code);
} else {
this.toastr.warning("no data found");
}
});
});
}

jobNo;
jobTitle;
entryDate;
LastModified: any;
code;
msgFrmDash;

ngOnInit() {
this.dtOption = {
"paging": false,
"ordering": true,
"info": false,
"searching": false,
"columnDefs": [
  { "orderable": false ,"targets": 6},
  { "orderable": false ,"targets": 7},
  { "orderable": true ,"targets": 5, "type": "date" }
]
}
}

getDocumentsList(linkCode: string) {
this.spinner.show();
this.documentsService
.getDocumentsForLink(encodeURIComponent(linkCode))
.subscribe((res) => {
  debugger
this.spinner.hide();
this.documentList = res.data;
this.distList = res.data[0].listName;
this.distListNo = res.data[0].listNo;
// this.documentList.forEach((element) => {
// debugger;
// if ((element.link !=null || element.link != "") && (element.link.indexOf("http://") != 0))
// element.link = "https://" + element.link;
// });

// this.tableData = this.documentList;
// this.dtOption = {
// data: this.tableData,
// columns: [
// {title: 'Doc#', data: 'docId'},
// {title: 'Doc Name', data: 'name'},
// {title: 'View', data: 'viewValue'},
// {title: 'Rev', data: 'rev'},
// {title: 'Item', data: 'item'},
// {title: 'Date', data: 'date'},
// {title: 'Link', data: 'link'},
// {title: 'Subject', data: 'subject'}
// ]
// };

this.checkType();
this.checkHeaderColor();
// console.log("sd", this.documentList);
});
}

gotoDocument(document) {

window.open(Constants.apiUrl +"User/GetDocument?jobNo="+document.jobNo+"&filename="+document.acutalFileName,"_blank");

// this.documentsService
// .getDocument(document.jobNo, document.name)
// .subscribe((res) => {
// // console.log('response from api');
// res.data.item1;
// });
}

checkType() {
this.documentList.forEach((document) => {
if(document.docType == 'F'){
    let type = document.acutalFileName.split(".");
    let ext = type[type.length - 1];
    debugger;
    if (ext == "pdf") document.icon = "assets/adobe.ico";
    else if ((ext == "dwg"))
    document.icon = "assets/autocad.ico";
    else if ((ext == "xls"))
    document.icon = "assets/spreadsheet.ico";
    else if ((ext == "doc"))
    document.icon = "assets/pen.ico";
    else if ((ext == "jpg"))
    document.icon = "assets/camera.ico";
    else if ((ext == "png"))
    document.icon = "assets/camera.ico";
    else if ((ext == "avi"))
    document.icon = "assets/movie.ico";
    else if ((ext == "wmv"))
    document.icon = "assets/movie.ico";
    else if ((ext == "mpg"))
    document.icon = "assets/movie.ico";
    else if ((ext == "mov"))
    document.icon = "assets/movie.ico";
    else if ((ext == "zip"))
    document.icon = "assets/zip.ico";
    else if ((ext == "skp"))
    document.icon = "assets/sketchup.ico";
    else if ((ext == "tml"))
    document.icon = "assets/sj.ico";
    else if ((ext == "3dm"))
    document.icon = "assets/rhino.ico";
    else if ((ext == "max"))
    document.icon = "assets/max.ico";
    else if ((ext == "dxf"))
    document.icon = "assets/dxficon.ico";
    else if ((ext == "dwf"))
    document.icon = "assets/dwf.ico";
    else if ((ext == "dwf"))
    document.icon = "assets/dwf.ico";
    else if ((ext == "stp"))
    document.icon = "assets/Step.ico";
    else if ((ext == "stl"))
    document.icon = "assets/STLico.ico";
    else if ((ext == "xxx"))
    document.icon = "assets/xxx.ico";
    else if ((ext == "dwf"))
    document.icon = "assets/DWFIcon.ico";
    else if ((ext == "dwf"))
    document.icon = "assets/DWFIcon.ico";
    else if ((ext == "wfx"))
    document.icon = "assets/DWFXicon.ico";
    else if ((ext == "ico"))
    document.icon = "assets/I-document.icon.ico";
    else if ((ext == "docx"))
    document.icon = "assets/pen.ico";
    else document.icon = "assets/qmark.ico";
    }
    else if(document.docType == 'L'){
    document.icon = "assets/link.ico";
    }
});
}

gotoLink(link){
window.open(link,"_blank")
}

OpenLink(document){
  var Link=Constants.baseUrl +"Docs/Job"+document.jobNo+"/"+document.acutalFileName;
  window.open(Link,"_blank")
  }

checkHeaderColor(){
if(this.DistType == "D1") this.headerColor ="#00c0ef";
else if(this.DistType == "D2") this.headerColor = "#00a65a";
else if(this.DistType == "D3") this.headerColor = "#f39c12";
else if(this.DistType == "D4") this.headerColor = "#dd4b39";

document.getElementById("hc").style.backgroundColor = this.headerColor;
}
}