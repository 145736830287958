import { Component, OnInit } from "@angular/core";
import { CookiesService } from "./services/cookies.service";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  title = "ClientApp";

  constructor(private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cookiesService: CookiesService,

  ) {}

  ngOnInit() {
    //debugger;
    let rout = this.router.url;
    console.log('current url', rout)
    

    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }
}
