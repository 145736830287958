import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: "app-userlist",
  templateUrl: "./userlist.component.html",
  styleUrls: ["./userlist.component.css"]
})
export class UserlistComponent implements OnInit {
  users: Array<any>;


  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private userService: UserService) {

  }

  ngOnInit() {
    this.getUserList();
  }

  getUserList() {
    this.spinner.show();
    this.userService.getUserList().subscribe(res => {
      this.spinner.hide();
      console.log('response from user list api', res);
      this.users = res.data;
    });
  }

  // addEditUser(user){
  //   ;
  //   if(user == 0){
  //     this.router.navigateByUrl('add-edit-user/0');
  //   }
  //   else if(user!=null){
  //     ;
  //     this.router.navigateByUrl('add-edit-user/' + user.id);
  //   }
  // }
}
