import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Status } from 'src/app/models/ResponseModel';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css']
})
export class JobListComponent implements OnInit {
  pageNo: any;
  constructor(private activeRoute:ActivatedRoute,public datePipe :DatePipe,private spinner: NgxSpinnerService,
     private UserService: UserService,private router :Router,private formBuilder:FormBuilder,
     private toastr: ToastrService) { }
  joblist: any=[];
  JobListFormGroup: FormGroup;
  pageno:any;
  limit:number=10;
  total:any; //total no of records
  ngOnInit() {
    this.JobListForm();
    this.pageNo = this.activeRoute.snapshot.paramMap.get("page")
    if(this.pageNo!=null && this.pageNo!=0){
      this.pageno=parseInt(this.pageNo);
      this.GetJobs();
    }
    else{
      this.pageno=1;
      this.GetJobs();
    }
    this.GetJobs();
  }
  JobListForm(): void {
    this.JobListFormGroup = this.formBuilder.group({
      JobNoSearch: [''],
      search: [''], 
      status:[0],
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  GetJobs()
  {
    this.spinner.show();
    let value=this.JobListFormGroup.value;
    value.pageno=this.pageno;
    value.pagesize=10;
    this.UserService.GetJobNumbersListSP(value).subscribe(resp=>{
      if(resp.status==Status.Success){
        this.joblist=resp.data.item1; 
        this.total=resp.data.item2;
        this.spinner.hide();
      }  
    })
  }
  SearchjobClick(){
    this.spinner.show();
    let value=this.JobListFormGroup.value;
    this.pageno=1;
    value.pageno=this.pageno;
    value.pagesize=10;
    this.UserService.GetJobNumbersListSP(value).subscribe(resp=>{
      if(resp.status==Status.Success){
        this.joblist=resp.data.item1; 
        this.total=resp.data.item2;
        this.spinner.hide();
      }  
    })
  }
  NewJobClick()
  {
    this.router.navigateByUrl("/addnewJob/0/0")
  }
  // EditJobClick(id)
  // {
  //   this.router.navigateByUrl("/addnewJob/"+id)
  // }
  DeleteJob(jobid) 
  {
    if(confirm("Are you sure to delete this job ?")){
      this.UserService.DeleteJob(jobid).subscribe(resp => {
        if (resp.data == true) {
          this.toastr.success(resp.message);
          window.location.reload();
        }
        else {
        }
      }); 
    }
  }
  SetpageNO(pageno){   
    this.pageno=pageno;
     this.GetJobs();
  }
  goToPage(n: number): void {
    this.pageno = n;
    this.GetJobs();
   }
   onNext(): void {
     this.pageno++;
     this.GetJobs();
   }
   onPrev(): void {
     this.pageno--;
     this.GetJobs();   
   }
}
