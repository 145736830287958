import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { CookiesService } from 'src/app/services/cookies.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm;
  passClass = 'password';
  loginError: boolean = false;

  constructor(
    private utilsService: UtilsService,
    private cookiesService: CookiesService,
    private router: Router,
    private fb: FormBuilder,
    private UserService: UserService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
    ) {
    this.loginForm = new FormGroup({
      userName: new FormControl('',Validators.required),
      password: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    document.getElementById('lock').addEventListener('click',this.viewPassword);
  }

  login(){

    console.log('this.loginForm.value: ', this.loginForm.value);
    let data = this.loginForm.value;
    this.spinner.show();
    this.UserService.login(data).subscribe(res=>{
      console.log('response from login api', res);
      if(res.status === 1){
        this.spinner.hide();
        this.router.navigateByUrl('/dashboard');
        this.toastr.success('Logged in successfully');
        this.cookiesService.setCookie('userName', data.userName);
        this.cookiesService.setCookie('password', data.password);
        localStorage.setItem('AuthToken', res.data);
        this.utilsService.GetUserData();
      }
      else if(res.status === 0){
        this.spinner.hide();
        // alert('please check your username or password');
        this.loginError = true;
      }
    })
  }

  viewPassword(){
    console.log('this.passClass: ', this.passClass);

    if(this.passClass === 'password') this.passClass = 'text';
    else if(this.passClass === 'text') this.passClass = 'password';
  }
}
