import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/public/login/login.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { DashboardComponent } from './components/shared/dashboard/dashboard.component';
import { ContactDetailsComponent } from './components/private/contact-details/contact-details.component';
import { AddContactComponent } from './components/private/add-contact/add-contact.component';
import { UserlistComponent } from './components/private/userlist/userlist.component';
import { JobListComponent } from './components/private/job-list/job-list.component';
import { AddNewJobComponent } from './components/private/add-new-job/add-new-job.component';
import { ProfileComponent } from './components/private/profile/profile.component';
import { AddEditUserComponent } from './components/private/add-edit-user/add-edit-user.component';
import { DocumentRegisterComponent } from './components/private/document-register/document-register.component';
import { DocumentListComponent } from './components/public/document-list/document-list.component';
import { AuthGuardService } from 'src/app/middleware/auth-guard.service'
import { NonAuthGuard } from './middleware/non-auth.guard';
import { ConfigParametersComponent } from './components/private/config-parameters/config-parameters.component';
import { ProgressFormComponent } from './components/private/progress-form/progress-form.component';
import { DefaultComponent } from './components/public/default/default.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [NonAuthGuard] ,data: {title: 'xyzcadApp-Login'}},
  { path: 'footer', component: FooterComponent },
  { path: 'dashboard', component: DashboardComponent,canActivate: [AuthGuardService],data: {title: 'xyzcadApp-Dashboard'} },
  //{ path: 'contacts', component: ContactDetailsComponent,canActivate: [AuthGuardService],data: {title: 'xyzcadApp-Contacts'} },
  { path: 'contacts/:page', component: ContactDetailsComponent,canActivate: [AuthGuardService],data: {title: 'xyzcadApp-Contacts'} },
  { path: 'addContact/:id/:page', component: AddContactComponent,canActivate: [AuthGuardService] ,data: {title: 'xyzcadApp-Edit-Contact'} },
 // { path: 'addContact', component: AddContactComponent,canActivate: [AuthGuardService] ,data: {title: 'xyzcadApp-Add-Contact'} },
  { path: 'user-list', component: UserlistComponent,canActivate: [AuthGuardService] ,data: {title: 'xyzcadApp-User-list'} },
  //{ path: 'jobs', component: JobListComponent,canActivate: [AuthGuardService] ,data: {title: 'xyzcadApp-Jobs'} },
  { path: 'jobs/:page', component: JobListComponent,canActivate: [AuthGuardService] ,data: {title: 'xyzcadApp-Jobs'} },
  //{ path: 'addnewJob', component: AddNewJobComponent,canActivate: [AuthGuardService] ,data: {title: 'xyzcadApp-AddNewJob'} },
  { path: 'addnewJob/:id/:page', component: AddNewJobComponent,canActivate: [AuthGuardService],data: {title: 'xyzcadApp-EditJob'} },
  { path: 'profile', component: ProfileComponent,canActivate: [AuthGuardService] ,data: {title: 'xyzcadApp-Profile'} },
  { path: 'add-edit-user/:id', component: AddEditUserComponent,canActivate: [AuthGuardService] ,data: {title: 'xyzcadApp-Edit-user'} },
  { path: 'documentRegister/:id', component: DocumentRegisterComponent,canActivate: [AuthGuardService] ,data: {title: 'xyzcadApp-DocumentRegister'} },
  { path: 'documentRegister', component: DocumentRegisterComponent,canActivate: [AuthGuardService] ,data: {title: 'xyzcadApp-DocumentRegister'} },
  { path: 'document-list', component: DocumentListComponent ,data: {title: 'xyzcadApp-Document-list'} },
  { path: 'config', component: ConfigParametersComponent,canActivate: [AuthGuardService]  ,data: {title: 'xyzcadApp-Config-Params'} },
  { path: 'progressForm/:jobNo', component: ProgressFormComponent,canActivate: [AuthGuardService]  ,data: {title: 'xyzcadApp-Progress-Form'} },
  //{ path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'default', component: DefaultComponent, canActivate: [NonAuthGuard] ,data: {title: 'xyzcadApp'}},
  { path: '', redirectTo: 'default', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
