import { Component, OnInit, TemplateRef , ViewChild, ElementRef } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators ,FormArray, FormControl} from '@angular/forms';
import {BsModalService ,BsModalRef} from "ngx-bootstrap/modal";
import { group } from '@angular/animations';
import { Status } from 'src/app/models/ResponseModel';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { Constants } from '../../../constants/constants';
import { CookiesService } from 'src/app/services/cookies.service';

@Component({
  selector: 'app-add-new-job',
  templateUrl: './add-new-job.component.html',
  styleUrls: ['./add-new-job.component.css']
})
export class AddNewJobComponent implements OnInit {
  @ViewChild(BsDatepickerDirective, { static: false }) datepicker: BsDatepickerDirective;
  @ViewChild('datePicker', null) datePicker: ElementRef;
  @ViewChild('dp', null) dp: ElementRef;
  bsdatepickerConfig: Partial<BsDatepickerConfig>;
  jobTitle: any;
  DistList1Code: any;
  DistList2Code: any;
  DistList3Code: any;
  DistList4Code: any;
  ProgressiveFormCode: any;
  RedirectCode: any;
  setDate: any;
  pageNo: any;

  //imgUrl :any= "assets/user2-160x160.jpg"
  imgUrl :any= "assets/NoImage.png"
  oldurl: string;
  imgerr:string;
  ImgName:string;
  @ViewChild('file', null) file: ElementRef;
  SelectedImgName: any;
  DistList1Mails: any;
  DistList2Mails: any;
  DistList3Mails: any;
  DistList4Mails: any;
  Dept1: any;
  Dept2: any;
  Dept3: any;
  Dept4: any;
  Dept5: any;
  Dept6: any;

  constructor(private cookiesService: CookiesService,private datePipe:DatePipe, private toastr: ToastrService,private activeRoute:ActivatedRoute,private UserService: UserService,private router :Router,private formBuilder:FormBuilder, private modalService: BsModalService) {
    this.rows = this.formBuilder.array([]);
   }
  AddNewJobFormGroup: FormGroup;
  AddContactFormGroup: FormGroup;
  contactlist: any=[];
  contacts: any=[];
  jobs: any;
  public bsModalRef: BsModalRef;
  jobNo:any;
  rows: FormArray;

  orderForm: FormGroup;
  items: FormArray;

  itemArray1:FormArray;
  itemArray2:FormArray;
  itemArray3:FormArray;
  itemArray4:FormArray;
  DeptArray:FormArray;
  UserId=this.cookiesService.getCookie('UserId');
  ngOnInit() {
    this.AddNewJobForm();
    this.AddContactForm();
    this.fillContactdrpdwn()
    this.orderForm = this.formBuilder.group({
      DistTitle:[null, Validators.required],
      items: this.formBuilder.array([ this.createItem() ])

    });
    //this.AddNewJobFormGroup.addControl('rows', this.rows);
    this.pageNo = this.activeRoute.snapshot.paramMap.get("page")
    this.jobNo = this.activeRoute.snapshot.paramMap.get("id")
    if(this.pageNo==null || this.pageNo==0){
      this.pageNo=1;
    }
    if(this.jobNo && this.jobNo!=null && this.jobNo!=0)
    {
      this.GetjobsById(this.jobNo);
      this.fillDistList1();
      this.fillDistList2();
      this.fillDistList3();
      this.fillDistList4();
    }
    else{
      this.GetLastJobNo();
    }
    this.GetImage();
    this.bsdatepickerConfig = Object.assign({}, { containerClass: 'theme-dark-blue', dateInputFormat: 'DD-MM-YYYY' });
  }
  AddNewJobForm(): void {
    this.AddNewJobFormGroup = this.formBuilder.group({
      JobNo:[null],
      JobTitle: [null, Validators.required],
      CreatedDate: [new Date(), Validators.required],
      Client: [null, Validators.required],
      ClientofClient: [null],
      CustomerDrawings: [null],
      Status: [null, Validators.required],
      WebStore: [null, Validators.required],
      DistributionList1: [null],
      DistributionList2: [null],
      DistributionList3: [null],
      DistributionList4: [null],
      DistributionList1Desc: this.formBuilder.array([  ]),
      DistributionList2Desc: this.formBuilder.array([  ]),
      DistributionList3Desc: this.formBuilder.array([  ]),
      DistributionList4Desc: this.formBuilder.array([  ]),
      Departments: this.formBuilder.array(this.GetFormArray()),
    })
  }

  GetFormArray(): any[] {
    let formArray :any[] = [];
    for (let index = 0; index < 6; index++) {
      formArray.push(this.formBuilder.group({
        JobDepartmentId:[0],
        DepartmentName : [''],
        DisplayOrder : [index+1],
      }))
    }
    return formArray;
  }
  get DepartmentArray()
  {
  return this.AddNewJobFormGroup.get('Departments') as FormArray
  }
  AddContactForm(): void {
    this.AddContactFormGroup = this.formBuilder.group({
      Firstname: [null, Validators.required],
      LastName: [null, Validators.required],
      CompanyName: [null, Validators.required],
      Address1: [null, Validators.required],
      Address2: [null],
      Address3: [null],
      Address4: [null],
      PostCode: [null, Validators.required],
      Phone1_Label: [null, Validators.required],
      Phone1_Description: [null, Validators.required],
      Phone2_Label: [null],
      Phone2_Description: [null],
      Email1_Label: [null, Validators.required],
      Email1_Description: [null, Validators.required],
      Email2_Label: [null],
      Email2_Description: [null],
      Website: [null, Validators.required],
      Field1_Label: [null],
      Field1_Description: [null, ],
      Field2_Label: [null],
      Field2_Description: [null],
      Field3_Label: [null],
      Field3_Description: [null],
    })
  }

  fillContactdrpdwn()
  {
        this.UserService.GetContactListById(null).subscribe(resp=>{
      if(resp.status==true){
        this.contactlist=resp.data;
      }
    })
  }

  GetLastJobNo()
  {
      this.UserService.GetLastJobNo().subscribe(resp=>{
      if(resp.status==Status.Success){
        this.AddNewJobFormGroup.get('JobNo').setValue(resp.data);
      }
    })
  }

  fillcontactsdetailsOnDrpdwnChange(contactid) {
    this.UserService.GetContactListById(contactid).subscribe(resp => {
      if (resp.status == true) {
        this.AddContactFormGroup.reset();
        this.contacts=resp.data;
        this.AddContactFormGroup.get('Firstname').setValue(this.contacts[0].firstName)
        this.AddContactFormGroup.get('LastName').setValue(this.contacts[0].lastName)
        this.AddContactFormGroup.get('CompanyName').setValue(this.contacts[0].companyName)
        this.AddContactFormGroup.get('Address1').setValue(this.contacts[0].address1)
        this.AddContactFormGroup.get('Address2').setValue(this.contacts[0].address2)
        this.AddContactFormGroup.get('Address3').setValue(this.contacts[0].address3)
        this.AddContactFormGroup.get('Address4').setValue(this.contacts[0].address4)
        this.AddContactFormGroup.get('PostCode').setValue(this.contacts[0].postCode)
        this.AddContactFormGroup.get('Phone1_Label').setValue(this.contacts[0].phone1_Label)
        this.AddContactFormGroup.get('Phone1_Description').setValue(this.contacts[0].phone1_Description)
        this.AddContactFormGroup.get('Phone2_Label').setValue(this.contacts[0].phone2_Label)
        this.AddContactFormGroup.get('Phone2_Description').setValue(this.contacts[0].phone2_Description)
        this.AddContactFormGroup.get('Email1_Label').setValue(this.contacts[0].email1_Label)
        this.AddContactFormGroup.get('Email1_Description').setValue(this.contacts[0].email1_Description)
        this.AddContactFormGroup.get('Email2_Label').setValue(this.contacts[0].email2_Label)
        this.AddContactFormGroup.get('Email2_Description').setValue(this.contacts[0].email2_Description)
        this.AddContactFormGroup.get('Website').setValue(this.contacts[0].website)
        this.AddContactFormGroup.get('Field1_Label').setValue(this.contacts[0].field1_Label)
        this.AddContactFormGroup.get('Field1_Description').setValue(this.contacts[0].field1_Description)
        this.AddContactFormGroup.get('Field2_Label').setValue(this.contacts[0].field2_Label)
        this.AddContactFormGroup.get('Field2_Description').setValue(this.contacts[0].field2_Description)
        this.AddContactFormGroup.get('Field3_Label').setValue(this.contacts[0].field3_Label)
        this.AddContactFormGroup.get('Field3_Description').setValue(this.contacts[0].field3_Description)
      }
    });
  }

//   OnAddClick() {
//     this.bsModalRef = this.modalService.show(AddDistributionListModalComponent);
//     this.bsModalRef.content.closeBtnName = 'Close';
// }

// onAddRow() {
//   this.rows = this.AddNewJobFormGroup.get('DistributionList1Desc') as FormArray;
//   this.rows.push(this.createItemFormGroup());
// }

// onRemoveRow(rowIndex:number){
//   this.items.removeAt(rowIndex);
// }

// createItemFormGroup(): FormGroup {
//   return this.formBuilder.group({
//     Client: '',
//     email1_Description: '',
//   });
// }
GetjobsById(jobNo){
  this.UserService.GetJobListById(jobNo).subscribe(resp => {
    if (resp.status == Status.Success) {
      this.jobs=resp.data;
      this.AddNewJobFormGroup.get('JobNo').setValue(this.jobs.jobNo)
      this.AddNewJobFormGroup.get('JobTitle').setValue(this.jobs.jobTitle)
      this.jobTitle=this.jobs.jobTitle;
     // this.setDate = this.datePipe.transform(this.jobs.createdDate, 'mm/dd/yyyy');
      this.AddNewJobFormGroup.get('CreatedDate').setValue(new Date(this.jobs.createdDate))
      this.AddNewJobFormGroup.get('Client').setValue(this.jobs.clientId)
      this.AddNewJobFormGroup.get('ClientofClient').setValue(this.jobs.clientofClient)
      this.AddNewJobFormGroup.get('CustomerDrawings').setValue(this.jobs.custDraw)
      let i= String(this.jobs.status);
      this.AddNewJobFormGroup.get('Status').setValue(i)
      this.AddNewJobFormGroup.get('WebStore').setValue(this.jobs.webStore)
      this.AddNewJobFormGroup.get('DistributionList1').setValue(this.jobs.distList1Desc)
      this.AddNewJobFormGroup.get('DistributionList2').setValue(this.jobs.distList2Desc)
      this.AddNewJobFormGroup.get('DistributionList3').setValue(this.jobs.distList3Desc)
      this.AddNewJobFormGroup.get('DistributionList4').setValue(this.jobs.distList4Desc)
      this.DistList1Code=this.jobs.distList1Code;
      this.DistList2Code=this.jobs.distList2Code;
      this.DistList3Code=this.jobs.distList3Code;
      this.DistList4Code=this.jobs.distList4Code;
      this.ProgressiveFormCode=this.jobs.progressiveFormCode;
      this.RedirectCode=encodeURIComponent(this.ProgressiveFormCode);
      this.DistList1Mails=this.jobs.distList1Mails;
      this.DistList2Mails=this.jobs.distList2Mails;
      this.DistList3Mails=this.jobs.distList3Mails;
      this.DistList4Mails=this.jobs.distList4Mails;
      this.ImgName=this.jobs.imageFile;
      this.GetImage();
        this.AddContactFormGroup.get('Firstname').setValue(this.jobs.firstName)
        this.AddContactFormGroup.get('LastName').setValue(this.jobs.lastName)
        this.AddContactFormGroup.get('CompanyName').setValue(this.jobs.companyName)
        this.AddContactFormGroup.get('Address1').setValue(this.jobs.address1)
        this.AddContactFormGroup.get('Address2').setValue(this.jobs.address2)
        this.AddContactFormGroup.get('Address3').setValue(this.jobs.address3)
        this.AddContactFormGroup.get('Address4').setValue(this.jobs.address4)
        this.AddContactFormGroup.get('PostCode').setValue(this.jobs.postCode)
        this.AddContactFormGroup.get('Phone1_Label').setValue(this.jobs.phone1_Label)
        this.AddContactFormGroup.get('Phone1_Description').setValue(this.jobs.phone1_Description)
        this.AddContactFormGroup.get('Phone2_Label').setValue(this.jobs.phone2_Label)
        this.AddContactFormGroup.get('Phone2_Description').setValue(this.jobs.phone2_Description)
        this.AddContactFormGroup.get('Email1_Label').setValue(this.jobs.email1_Label)
        this.AddContactFormGroup.get('Email1_Description').setValue(this.jobs.email1_Description)
        this.AddContactFormGroup.get('Email2_Label').setValue(this.jobs.email2_Label)
        this.AddContactFormGroup.get('Email2_Description').setValue(this.jobs.email2_Description)
        this.AddContactFormGroup.get('Website').setValue(this.jobs.website)
        this.AddContactFormGroup.get('Field1_Label').setValue(this.jobs.field1_Label)
        this.AddContactFormGroup.get('Field1_Description').setValue(this.jobs.field1_Description)
        this.AddContactFormGroup.get('Field2_Label').setValue(this.jobs.field2_Label)
        this.AddContactFormGroup.get('Field2_Description').setValue(this.jobs.field2_Description)
        this.AddContactFormGroup.get('Field3_Label').setValue(this.jobs.field3_Label)
        this.AddContactFormGroup.get('Field3_Description').setValue(this.jobs.field3_Description)

        this.UserService.GetJobDepartmentsListById(jobNo).subscribe(resp => {
          if (resp.status == Status.Success)
          {
            debugger
            if(resp.data.length>0){
              let count=0;
              var data=resp.data;
              this.DepartmentArray.clear();
              data.forEach( (element) => {
                count=count+1;
                this.DepartmentArray.push(this.formBuilder.group({
                  JobDepartmentId:element.jobDepartmentId,
                  DepartmentName : element.deptName,
                  DisplayOrder : element.displayOrder
                }))
            });
            if(count<6){
              for (let index = count+1; index <= 6; index++) {
                this.DepartmentArray.push(this.formBuilder.group({
                  JobDepartmentId:[0],
                  DepartmentName : [''],
                  DisplayOrder : [index],
                }))
              }
            }
            }
            // else{
            //   this.DepartmentArray.push(this.formBuilder.array(this.GetFormArray()));
            // }
          }
        });
    }
  });
}

saveJobClick()
{
debugger
  let value = this.AddNewJobFormGroup.value;
   //this.DeptArray=this.AddNewJobFormGroup.get('Departments') as FormArray;
   let NewArray=[];
   let count=0;
   if(value.Departments){
   value.Departments.forEach(element => {
     if(element.DepartmentName !=""){
      NewArray.push({
        JobDepartmentId:element.JobDepartmentId,
          DepartmentName :element.DepartmentName,
          DisplayOrder : ++count,
      })
     }
   });
  }
  console.log(NewArray);
  value.Departments=NewArray;
  value.DistributionContactDetail1=this.itemArray1;
  value.DistributionContactDetail2=this.itemArray2;
  value.DistributionContactDetail3=this.itemArray3;
  value.DistributionContactDetail4=this.itemArray4;
  value.CreatedBy=this.UserId;
  value.CreatedDate=this.datePipe.transform(value.CreatedDate, 'yyyy-MM-dd HH:mm:ss');
  value.ImageFile =this.SelectedImgName;
  if(this.jobNo!=null && this.jobNo!=undefined && this.jobNo&& this.jobNo!=0)
  {
    value.JobNo=this.jobNo;
  }
  else{
    value.JobNo=null;
  }

  let files = this.file.nativeElement.files;
  let formdata = new FormData();
  if (files.length > 0) {
    for (var i = 0; i < files.length; i++) {
      formdata.append("files", files[i], files[i].name)
    }
  }
 // value.ImageName = this.ImgName;
  for (var key in value) {
    if (value[key] == null) {
      continue;
    }
    if(key=='DistributionContactDetail1'){
      value[key] = JSON.stringify(value[key]);  
  }
  if(key=='DistributionContactDetail2'){
    value[key] = JSON.stringify(value[key]);  
}
if(key=='DistributionContactDetail3'){
  value[key] = JSON.stringify(value[key]);  
}
if(key=='DistributionContactDetail4'){
  value[key] = JSON.stringify(value[key]);  
}
if(key=='Departments'){
  value[key] = JSON.stringify(value[key]);  
}
    formdata.append(key, value[key]);
  }


    this.UserService.AddJob(formdata).subscribe(resp => {
      if (resp.status == Status.Success) {
        if(this.jobNo && this.jobNo!=0){
          this.toastr.success("Job Updated Successfully");
        }
        else{
          this.toastr.success("Job Created Successfully");
        }
        this.AddNewJobFormGroup.reset();
        this.GetjobsById(this.jobNo);
       // this.router.navigateByUrl("/jobs/"+this.pageNo)
      }
      else {
      }
    });
}
openModal1(template1: TemplateRef<any>) {
  this.items =this.formBuilder.array([ this.createItem() ]);
  this.bsModalRef = this.modalService.show(template1);
  if(this.jobNo!=null && this.jobNo!=0)
  {
  this.orderForm.get('DistTitle').setValue(this.AddNewJobFormGroup.value.DistributionList1)

    var obj = {
      jobNo:this.jobNo,
      ListId:1
   };
    this.UserService.GetJobDistributionListSPById(obj).subscribe(resp => {
      if (resp.status == Status.Success)
      {

          // console.log(resp.data);
          var data=resp.data;
          this.items.clear();
          this .itemArray.clear();
          data.forEach( (element) => {

            this.itemArray.push(this.formBuilder.group({
              Sno: element.sno,
              Client: element.contactId,
              email: element.email
            }))
        });
      }
    });
  }
}


SaveDisList1(){

 this.itemArray1= this.orderForm.value.items;
 this.AddNewJobFormGroup.get('DistributionList1').setValue(this.orderForm.value.DistTitle)
//  console.log(this.itemArray1);
 this.bsModalRef.hide()
 this.orderForm.reset();
 this.items.clear();
 this.items.push(this.createItem())
}

fillDistList1()
{
  var obj = {
    jobNo:this.jobNo,
    ListId:1
 };
  this.UserService.GetJobDistributionListSPById(obj).subscribe(resp => {
    if (resp.status == Status.Success)
    {

        var data=resp.data;
        this .itemArray.clear();
        data.forEach( (element) => {

          this.itemArray.push(this.formBuilder.group({
            Sno: element.sno,
            Client: element.contactId,
            email: element.email
          }))
      });
      this.itemArray1= this.orderForm.value.items;
      // console.log(this.itemArray1);
    }
  });
}

createItem(): FormGroup {
  return this.formBuilder.group({
    Sno: [''],
    Client: ['0', Validators.required],
    email: ['']
  });
}

addItem(): void {
  this.items = this.orderForm.get('items') as FormArray;
  this.items.push(this.createItem());
}
onRemoveItem(rowIndex:number){
  this.items = this.orderForm.get('items') as FormArray;
  this.items.removeAt(rowIndex);
}

fillemailOnDrpdwnChange(contactid,i)
{
  this.UserService.GetContactListById(contactid).subscribe(resp => {
    if (resp.status == true) {

      this.contacts=resp.data;
     this .itemArray.removeAt(i);
      this.itemArray.push(this.formBuilder.group({
        Sno: i+1,
        Client: contactid,
        email: this.contacts[0].email1_Description
      }))
    }
  });
}
get itemArray()
{
return this.orderForm.get('items') as FormArray
}

openModal2(template2: TemplateRef<any>) {
  this.items =this.formBuilder.array([ this.createItem() ]);
  this.bsModalRef = this.modalService.show(template2);
  if(this.jobNo!=null && this.jobNo!=0)
  {
  this.orderForm.get('DistTitle').setValue(this.AddNewJobFormGroup.value.DistributionList2)

    var obj = {
      jobNo:this.jobNo,
      ListId:2
   };
    this.UserService.GetJobDistributionListSPById(obj).subscribe(resp => {
      if (resp.status == Status.Success)
      {

          // console.log(resp.data);
          var data=resp.data;
         // this.items.clear();
          this .itemArray.clear();
          data.forEach( (element) => {

            this.itemArray.push(this.formBuilder.group({
              Sno: element.sno,
              Client: element.contactId,
              email: element.email
            }))
        });
      }
    });
  }
}
SaveDisList2(){
 this.itemArray2= this.orderForm.value.items;
 this.AddNewJobFormGroup.get('DistributionList2').setValue(this.orderForm.value.DistTitle)
//  console.log(this.itemArray2);
 this.bsModalRef.hide()
 this.orderForm.reset();
 this.items.clear();
 this.items.push(this.createItem())
}

fillDistList2()
{
  var obj = {
    jobNo:this.jobNo,
    ListId:2
 };
  this.UserService.GetJobDistributionListSPById(obj).subscribe(resp => {
    if (resp.status == Status.Success)
    {

        var data=resp.data;
        this .itemArray.clear();
        data.forEach( (element) => {

          this.itemArray.push(this.formBuilder.group({
            Sno: element.sno,
            Client: element.contactId,
            email: element.email
          }))
      });
      this.itemArray2= this.orderForm.value.items;
      // console.log(this.itemArray2);
    }
  });
}

openModal3(template3: TemplateRef<any>) {
  this.items =this.formBuilder.array([ this.createItem() ]);
  this.bsModalRef = this.modalService.show(template3);
  if(this.jobNo!=null && this.jobNo!=0)
  {
  this.orderForm.get('DistTitle').setValue(this.AddNewJobFormGroup.value.DistributionList3)

    var obj = {
      jobNo:this.jobNo,
      ListId:3
   };
    this.UserService.GetJobDistributionListSPById(obj).subscribe(resp => {
      if (resp.status == Status.Success)
      {

          // console.log(resp.data);
          var data=resp.data;
          this.items.clear();
          this .itemArray.clear();
          data.forEach( (element) => {

            this.itemArray.push(this.formBuilder.group({
              Sno: element.sno,
              Client: element.contactId,
              email: element.email
            }))
        });
      }
    });
  }
}
SaveDisList3(){
 this.itemArray3= this.orderForm.value.items;
 this.AddNewJobFormGroup.get('DistributionList3').setValue(this.orderForm.value.DistTitle)
//  console.log(this.itemArray3);
 this.bsModalRef.hide()
 this.orderForm.reset();
 this.items.clear();
 this.items.push(this.createItem())
}

fillDistList3()
{
  var obj = {
    jobNo:this.jobNo,
    ListId:3
 };
  this.UserService.GetJobDistributionListSPById(obj).subscribe(resp => {
    if (resp.status == Status.Success)
    {

        var data=resp.data;
        this .itemArray.clear();
        data.forEach( (element) => {

          this.itemArray.push(this.formBuilder.group({
            Sno: element.sno,
            Client: element.contactId,
            email: element.email
          }))
      });
      this.itemArray3= this.orderForm.value.items;
      // console.log(this.itemArray3);
    }
  });
}

openModal4(template4: TemplateRef<any>) {
  this.items =this.formBuilder.array([ this.createItem() ]);
  this.bsModalRef = this.modalService.show(template4);
  if(this.jobNo!=null && this.jobNo!=0)
  {
  this.orderForm.get('DistTitle').setValue(this.AddNewJobFormGroup.value.DistributionList4)

    var obj = {
      jobNo:this.jobNo,
      ListId:4
   };
    this.UserService.GetJobDistributionListSPById(obj).subscribe(resp => {
      if (resp.status == Status.Success)
      {

          // console.log(resp.data);
          var data=resp.data;
          this.items.clear();
          this .itemArray.clear();
          data.forEach( (element) => {

            this.itemArray.push(this.formBuilder.group({
              Sno: element.sno,
              Client: element.contactId,
              email: element.email
            }))
        });
      }
    });
  }
}
SaveDisList4(){
 this.itemArray4= this.orderForm.value.items;
 this.AddNewJobFormGroup.get('DistributionList4').setValue(this.orderForm.value.DistTitle)
//  console.log(this.itemArray4);
 this.bsModalRef.hide()
 this.orderForm.reset();
 this.items.clear();
 this.items.push(this.createItem())
}

fillDistList4()
{
  var obj = {
    jobNo:this.jobNo,
    ListId:4
 };
  this.UserService.GetJobDistributionListSPById(obj).subscribe(resp => {
    if (resp.status == Status.Success)
    {

        var data=resp.data;
        this .itemArray.clear();
        data.forEach( (element) => {

          this.itemArray.push(this.formBuilder.group({
            Sno: element.sno,
            Client: element.contactId,
            email: element.email
          }))
      });
      this.itemArray4= this.orderForm.value.items;
      // console.log(this.itemArray4);
    }
  });
}

Back(){
  this.router.navigateByUrl("/jobs/"+this.pageNo);
}


// To copy address
copyInputMessage(jobNo, jobTitle, distList1Code) {
  let date = new Date(this.jobs.createdDate);
  date.toDateString();
  let txt = Constants.baseUrl + 'document-list?code='+encodeURIComponent(distList1Code);

  let selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = txt;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  this.toastr.success("link copied to clipboard");
}

GetImage()
{debugger
  if (this.ImgName != null && this.ImgName != '') {
    this.UserService.GetImageUrl(this.ImgName).subscribe(resp => {
      if (resp.status == Status.Success) {
        this.imgUrl = resp.data;
      }
    })
  }
}
OnFileSelect() {
  debugger
  var files = this.file.nativeElement.files;
    if (files && files[0]) {
      var type = files[0].type;
      if (type == 'image/x-png' || type == 'image/gif' || type == 'image/jpeg' || type == 'image/jpg' || type == 'image/png') {
        var reader = new FileReader();
        reader.onloadend = () => {
          this.imgUrl = reader.result.toString();
          this.SelectedImgName=files[0].name;
        }
        reader.readAsDataURL(files[0]);
      }
      else {
        this.file.nativeElement.files = null;
        this.imgUrl = this.oldurl;
       // this.toastr.success("Please Upload Only Images");
       this.imgerr="*Please Upload Only Images";
      }
    }
}

copyEmail(distList1Code) {
  let txt =distList1Code;
  let selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = txt;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  this.toastr.success("email copied to clipboard");
}

copyProgressLink() {
  let txt =Constants.baseUrl +'progressForm/'+encodeURIComponent(this.ProgressiveFormCode);
  let selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = txt;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  this.toastr.success("Progress Link copied to clipboard");
}

}
