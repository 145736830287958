import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, Form } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { Status } from 'src/app/models/ResponseModel';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/constants/constants';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/public_api';
import { CookiesService } from 'src/app/services/cookies.service';
import { ValueTransformer } from '@angular/compiler/src/util';

@Component({
  selector: 'app-document-register',
  templateUrl: './document-register.component.html',
  styleUrls: ['./document-register.component.css']
})
export class DocumentRegisterComponent implements OnInit {
  @ViewChild('datePicker', null) datePicker: ElementRef;
  bsdatepickerConfig: Partial<BsDatepickerConfig>;
  public endpointurl = Constants.apiUrl;
  DocRegiserData: any;
  public bsModalRef: BsModalRef;
  result: any;
  //itemArray1: any;
  DocRegId: any;
  documents: any;
  joblist: any;
  changedfiles: any;
  result1: string;
  ModifyDocId: any;
  setDate: any;
  OldFileName: any;
  UserId=this.cookiesService.getCookie('UserId');
  constructor(private cookiesService: CookiesService,private datePipe :DatePipe,private toastr: ToastrService,private formBuilder:FormBuilder,private activeRoute:ActivatedRoute,private UserService: UserService, private modalService: BsModalService) { }
  AddLinkFormGroup: FormGroup;
  DocumentRegisterTableForm: FormGroup;
  AddFileFormGroup: FormGroup;
  items:FormArray;
  value:any=[];
  files:any;
  jobno:any;
  FileForm: FormGroup;
  FileItems: FormArray;
  ngOnInit() {
    this.AddFileForm();
    this.AddNewLinkForm();
    this.DocumentRegisterTableForm = this.formBuilder.group({
      D1: [0],
      D2: [0],
      D3: [0],
      D4: [0],
      items: this.formBuilder.array([  ])  //this.createItem()
    });
  this.FillJobDropdown();
  this.jobno = this.activeRoute.snapshot.paramMap.get("id")
  if(this.jobno!=null){
    debugger
    this.AddFileFormGroup.get('JobNo').setValue(this.jobno);
    this.GetDocsRegisterByJobNo(this.jobno);
  }
  this.bsdatepickerConfig = Object.assign({}, { containerClass: 'theme-dark-blue', dateInputFormat: 'DD-MM-YYYY' });


  this.FileForm = this.formBuilder.group({
    FileItems: this.formBuilder.array([ ])
  });
  }

  AddNewLinkForm(): void {
    this.AddLinkFormGroup = this.formBuilder.group({
      Name: [null],
      Rev: [null],
      Item: [null, Validators.required],
      Link: [null, Validators.required],
      Subject: [null, Validators.required],
    })
  }

  AddFileForm(): void {
    this.AddFileFormGroup = this.formBuilder.group({
      JobNo: [0,Validators.required],
      JobTitle: [null],
      JobPWD: [null],
      DocRegId: [null],
    })
  }

  
  createItem(): FormGroup {
    return this.formBuilder.group({
      DocVer:[''],
      DocId: [''], //0
      DocRegId: [''],
      Name: [''],//DbaseList_reva.pdf
      ViewValue: [''],
      Rev: [''],
      Item: [''],
      Link: [''],
      Date: [new Date()],
      Subject: [''],
      Approved: [''],
      ActualFileName: [''],
      D1: [false],
      D2: [false],
      D3: [false],
      D4: [false],
      DocType:['']
    });
  }
  addItem(): void {
    this.items = this.DocumentRegisterTableForm.get('items') as FormArray;
    this.items.push(this.createItem());
  }
  onRemoveItem(rowIndex:number){
    this.items = this.DocumentRegisterTableForm.get('items') as FormArray;
    this.items.removeAt(rowIndex);
  }
  get itemArray1()
  {
  return this.DocumentRegisterTableForm.get('items') as FormArray
  }
get itemArray()
{
return this.DocumentRegisterTableForm.get('items') as FormArray
}
FillJobDropdown(){
        this.UserService.GetJobList(null).subscribe(resp=>{
      if(resp.status==Status.Success){
        this.joblist=resp.data; 
      }  
    })
}
openAddLinkModal(AddLinkTemp: TemplateRef<any>) {
 // this.items =this.formBuilder.array([ this.createItem() ]);
  this.bsModalRef = this.modalService.show(AddLinkTemp);
}

openAddFileModal(AddFileTemp: TemplateRef<any>) {
  this.result ="";
  this.FileArray.clear();
   this.bsModalRef = this.modalService.show(AddFileTemp);
 }

 get FileArray()
 {
 return this.FileForm.get('FileItems') as FormArray
 }

 OnFileSelect(event: any): void {
   debugger
   this.files=event.target.files;
  var selectedFiles = event.target.files;

  var fileNames = [];
  for (var i = 0; i < selectedFiles.length; i++) {
  fileNames.push(selectedFiles[i].name);
  }
  if(this.jobno!=null){
    let value=this.AddFileFormGroup.value;
    value.jobNo=this.jobno;
    value.fileNames=fileNames;
    this.UserService.checkFileExists(value).subscribe(resp=>{
      if(resp.status==Status.Success){
        var data =resp.data;
        console.log(data);
        this.result = '<br>You have selected following files:<br>';
        // for (var i = 0; i < data.length; i++) {
          
        //   this.result +='<span style=font-size: large; >'+(i+1)+' : ' + data[i].filename +'<br></span>  <input type="checkbox" class="chk" checked />'
        //   //this.result += (i+1)+ ': ' + data[i].filename +' : <input type="checkbox" class="chk" /> '+data[i].status+'<br>';
        // }
debugger

        var data=resp.data;
         this.FileArray.clear();
        let Formarray=this.FileForm.get('FileItems') as FormArray
        data.forEach( (element) => {

          this.FileArray.push(this.formBuilder.group({
            Filename:[element.filename],
            Status:  [element.status],
            // OverWrite:  [element.overWrite],
            // Remove:[element.delete],
            OverWrite:  [false],
            Remove:[false],
          }))
          console.log(Formarray)
      });
     

      }  
    })
  }
  else{
     this.result = '<br>Please select JobNo to add files<br>';
  }
 

  // this.result = '<br>You have selected following files:<br>';
  // for (var i = 0; i < selectedFiles.length; i++) {
    
  //   this.result += (i+1)+ ': ' + selectedFiles[i].name +'<br>';
  // }

  //this.DocumentRegisterTableForm.controls.items.value[0].get('Name').setValue(this.files[0].name);
}
AddFile()
{
  debugger
  let files1=this.FileForm.value.FileItems;
  console.log(files1);
this.itemArray1.clear();
if(this.DocRegId==null)
{
  if(this.files.length>0 && files1.length > 0)
  {
    for (var i = 0; i < files1.length; i++) 
    {
      if(files1[i].Remove==false)
      {
        this.itemArray1.push(this.formBuilder.group({
          DocId: [0],
          DocRegId: [0],
          DocVer:[''],
          Name: [this.files[i].name],
          ViewValue: [''],
          Rev: [''],
          Item: [''],
          Link: [''],
          Date: [new Date(this.files[i].lastModifiedDate)],
          Subject: [''],
          Approved: [''],
          ActualFileName: [this.files[i].name],
          D1: [false],
          D2: [false],
          D3: [false],
          D4: [false],
          DocType:['']
        }))
      }
      }
  }


  // if(this.files.length>0)
  // {
  //   for (var i = 0; i < this.files.length; i++) 
  //   {
  //     this.itemArray1.push(this.formBuilder.group({
  //       DocId: [0],
  //       DocRegId: [0],
  //       DocVer:[''],
  //       Name: [this.files[i].name],
  //       ViewValue: [''],
  //       Rev: [''],
  //       Item: [''],
  //       Link: [''],
  //       Date: [new Date(this.files[i].lastModifiedDate)],
  //       Subject: [''],
  //       Approved: [''],
  //       ActualFileName: [this.files[i].name],
  //       D1: [false],
  //       D2: [false],
  //       D3: [false],
  //       D4: [false],
  //       DocType:['']
  //     }))
  //   }
  // }

  let value=this.AddFileFormGroup.value;
  value.ID=0;
  value.DocRegId=0;
  value.UserId=this.UserId;
  value.Documents=this.itemArray1.value;

  let files = this.files;
  let formdata = new FormData();
  if (files.length > 0 && files1.length > 0) {
    for (var i = 0; i < files.length; i++) {
      if(files1[i].Remove==false)
      {
        formdata.append("files", files[i], files[i].name)
      }
    }
  }
  for (var key in value) {
    if (value[key] == null) {
      continue;
    }
    if(key=='Documents'){
      value[key] = JSON.stringify(value[key]);  
  }
    formdata.append(key, value[key]);
  }
 
  this.UserService.AddfileSave(formdata).subscribe(resp=>{
    if(resp.status==Status.Success){
      this.GetDocsRegisterByJobNo(this.jobno);
      this.bsModalRef.hide();
      this.AddFileFormGroup.reset();
    }  
  })
}
else
{
  debugger
  let files1=this.FileForm.value.FileItems;
  console.log(files1);

  let value=this.AddFileFormGroup.value;
  value.UserId=this.UserId;
  value.Name=this.jobno;
  let files = this.files;
  var DatesArray = [];
  for (var i = 0; i < files.length; i++) {
    if(files1[i].Remove==false)
      {
        var convDate =new Date(this.files[i].lastModifiedDate)
        DatesArray.push(convDate);
      }
  }
    value.Dates=DatesArray
  let formdata = new FormData();
  if (files.length > 0 && files1.length > 0) 
  {
    for (var i = 0; i < files.length; i++) {
      if(files1[i].Remove==false)
      {
        formdata.append("files", files[i], files[i].name)
      }
    }
  }
  for (var key in value) {
    if (value[key] == null) {
      continue;
    }
    formdata.append(key, value[key]);
  }

  this.UserService.DocNewfileInsert(formdata).subscribe(resp=>{
    debugger
    if(resp.status==Status.Success){
      debugger
      this.GetDocsRegisterByJobNo(this.jobno);
      this.bsModalRef.hide();
      this.AddFileFormGroup.reset();
    }  
  })
}
}

OnJobChange(jobno){
  this.jobno=jobno;
  this.AddFileFormGroup.get('JobNo').setValue(jobno);
  this.DocRegId=null;
  this.GetDocsRegisterByJobNo(jobno)
}

GetPrevDocDetails(){
 var prevalue= (this.AddFileFormGroup.controls.JobNo.value-1);
  this.DocRegId=null;
  this.GetDocsRegisterByJobNo((prevalue))
}
GetNextDocDetails(){
  var nextvalue= (this.AddFileFormGroup.controls.JobNo.value+1);
  this.DocRegId=null;
  this.GetDocsRegisterByJobNo((nextvalue))
}

GetDocsRegisterByJobNo(jobNo){
  this.AddFileFormGroup.reset();
  this .itemArray.clear();
  this.UserService.GetDocsRegisterByJobNo(jobNo).subscribe(resp=>{
    if(resp.status==Status.Success){
      debugger
       if(resp.data!= null){
      this.DocRegiserData=resp.data; 
      if(resp.data.docRegId!=null&&resp.data.docRegId!=undefined){
      this.DocRegId=resp.data.docRegId;
    }
      this.jobno=jobNo;
      this.AddFileFormGroup.get('JobNo').setValue(jobNo);
      this.AddFileFormGroup.get('JobPWD').setValue(resp.data.webStore);
      this.AddFileFormGroup.get('DocRegId').setValue(resp.data.docRegId);
      this.AddFileFormGroup.get('JobTitle').setValue(resp.data.jobTitle);
      if(this.DocRegId!=null){
     this.GetDocumentsByRegisterID(this.DocRegId);
    }
    }  
    else{
      this.AddFileFormGroup.get('JobNo').setValue(jobNo);
      this.toastr.warning("no record found");
    }
  }
   
  this.AddFileFormGroup.get('JobNo').setValue(jobNo);
  })
}

GetDocumentsByRegisterID(DocRegId){
  this .itemArray.clear();
  this.UserService.GetDocumentsByRegisterID(DocRegId).subscribe(resp=>{
    if(resp.status==Status.Success){
      debugger;
      this.documents=resp.data; 
      console.log(resp.data);
     
      if(this.documents.length>0){
      // this .itemArray.clear()
        this.documents.forEach( (element) => {
          console.log(element.webstoreFile);
       this.setDate = this.datePipe.transform(element.date, 'dd/MM/yyyy');
        this.itemArray.push(this.formBuilder.group({
        DocId: [element.docId],
        DocRegId: [this.DocRegId],
        DocVer:[element.docVer],
        Name: [element.name],
        ViewValue: [element.viewValue],
        Rev: [element.rev],
        Item: [element.item],
        Link: [element.link],
        Date: [new Date(element.date)],
        Subject: [element.subject],
        Approved: [element.approved],
        ActualFileName:[element.acutalFileName],
        D1: [element.d1],
        D2: [element.d2],
        D3: [element.d3],
        D4: [element.d4],
        DocType:[element.docType],
        WebstoreFile:[element.webstoreFile],
          Icon: this.checkType(element.docType, element.acutalFileName)
        }))
      });
      }
     // this.checkType();
    }  
  })
}

GetDocTypeValue(i){
// var type= this.DocumentRegisterTableForm.controls.items.controls[i].controls.DocType.value;
var type= this.DocumentRegisterTableForm.value.items[i].DocType;
 return type;
}

GetDocIdValue(i){
var Id= this.DocumentRegisterTableForm.value.items[i].DocId;
 return Id;
}
GetOldFileName(i){
  debugger
  var fileName= this.DocumentRegisterTableForm.value.items[i].ActualFileName;
 return fileName;
}
GetDateValue(i){
  debugger
  var date= this.DocumentRegisterTableForm.value.items[i].Date;
 return date;
}
AddLink(){
  debugger;
  let value=this.AddLinkFormGroup.value;
  value.DocRegId=this.DocRegId;
  value.UserId=this.UserId;
  value.D1=false;
  value.D2=false;
  value.D3=false;
  value.D4=false;
  this.UserService.AddLinkSave(value).subscribe(resp=>{
    if(resp.status==Status.Success){
      this.GetDocsRegisterByJobNo(this.jobno);
      this.bsModalRef.hide();
      this.AddLinkFormGroup.reset();
    }  
  })
}

openModifyFileModal(ModifyFileTemp: TemplateRef<any>,docid,oldfileName) {
  debugger
  this.result1 ="";
  this.bsModalRef = this.modalService.show(ModifyFileTemp);
  this.ModifyDocId=docid;
  this.OldFileName=oldfileName;
  console.log("Hello"+docid)
  console.log(oldfileName)
}

OnFileChange(event: any): void {
  debugger
  this.changedfiles=event.target.files;
 var selectedFiles = event.target.files;
 this.result1 = '<br>You have selected following files:<br>';
 for (var i = 0; i < selectedFiles.length; i++) {
   this.result1 += (i+1)+ ': ' + selectedFiles[i].name +'<br>';
 }
}

Modifyfile(){
  debugger;
  let value=this.AddLinkFormGroup.value;
  value.DocRegId=this.ModifyDocId;
  value.Subject=this.AddFileFormGroup.controls.JobNo.value;
  value.Name=this.OldFileName;

  var date =new Date(this.changedfiles[0].lastModifiedDate);
 // let convDate = this.datePipe.transform(date,'dd-MM-yyyy')
   value.Date=date;
  //value.Date=new Date(this.changedfiles[0].lastModifiedDate);
  value.UserId=this.UserId;

  let files = this.changedfiles;
  let formdata = new FormData();
  if (files.length > 0) {
    for (var i = 0; i < files.length; i++) {
      formdata.append("files", files[i], files[i].name)
    }
  }
  for (var key in value) {
    if (value[key] == null) {
      continue;
    }
    formdata.append(key, value[key]);
  }
  this.UserService.ModifyFile(formdata).subscribe(resp=>{
    if(resp.status==Status.Success){
      this.GetDocsRegisterByJobNo(this.jobno);
      this.bsModalRef.hide();
      this.AddLinkFormGroup.reset();
    }  
  })
}

Update()
{
  debugger
  let value=this.AddFileFormGroup.value;
  value.ID=this.DocRegId;
  value.UserId=this.UserId;
  value.Document=this.DocumentRegisterTableForm.controls.items.value;
  for(var i=0;i<value.Document.length;i++){
    //value.Document[i].Date= new Date(value.Document[i].Date)
    //var date =this.datePipe.transform(value.Document[i].Date,'MM/dd/yyyy hh:mm.ss')
   var date = new Date(value.Document[i].Date);
    value.Document[i].Date= date;
    if(value.Document[i].D1==null){
      value.Document[i].D1=false
    }
    if(value.Document[i].D2==null){
      value.Document[i].D2=false
    }
    if(value.Document[i].D3==null){
      value.Document[i].D3=false
    }
    if(value.Document[i].D4==null){
      value.Document[i].D4=false
    }
  }
  // value.Document.forEach(element => {
  //   element.Date= Date.parse(element.Date)
  // });
  this.UserService.UpdateDocument(value).subscribe(resp=>{
    if(resp.status==Status.Success){
      this.toastr.success(resp.message);
      this.AddFileFormGroup.reset();
      this.GetDocsRegisterByJobNo(this.jobno);
      this.bsModalRef.hide();
      
    }  
  })
}

DeleteDocument(docID){
  debugger
  if(confirm("Are you sure to delete this Document/Link ?")){
    let value=this.AddFileFormGroup.value;
    value.docId=docID;
    value.UserId=this.UserId;
    this.UserService.DeleteDocument(value).subscribe(resp=>{
      if(resp.status==Status.Success){
        this.AddFileFormGroup.reset();
        this.GetDocsRegisterByJobNo(this.jobno);
        this.toastr.success(resp.message)
      }  
    })
  }
  
}
sortByDateAsc(){
  debugger
  let value=this.AddFileFormGroup.value;
  value.SortBy='date';
  value.SortAsc=true ;
  value.D1=this.DocumentRegisterTableForm.controls.D1.value;
  value.D2=this.DocumentRegisterTableForm.controls.D2.value;
  value.D3=this.DocumentRegisterTableForm.controls.D3.value;
  value.D4=this.DocumentRegisterTableForm.controls.D4.value;
  this.GetSortedDocumentsByRegisterID(value)
}
sortByDateDesc(){
  debugger
  let value=this.AddFileFormGroup.value;
  value.SortBy='date';
  value.SortAsc=false ;
  value.D1=this.DocumentRegisterTableForm.controls.D1.value;
  value.D2=this.DocumentRegisterTableForm.controls.D2.value;
  value.D3=this.DocumentRegisterTableForm.controls.D3.value;
  value.D4=this.DocumentRegisterTableForm.controls.D4.value;
  this.GetSortedDocumentsByRegisterID(value)
}
sortBySubjectAsc(){
  debugger
  let value=this.AddFileFormGroup.value;
  value.SortBy='subject';
  value.SortAsc=true ;
  value.D1=this.DocumentRegisterTableForm.controls.D1.value;
  value.D2=this.DocumentRegisterTableForm.controls.D2.value;
  value.D3=this.DocumentRegisterTableForm.controls.D3.value;
  value.D4=this.DocumentRegisterTableForm.controls.D4.value;
  this.GetSortedDocumentsByRegisterID(value)
}
sortBySubjectDesc(){
  debugger
  let value=this.AddFileFormGroup.value;
  value.SortBy='subject';
  value.SortAsc=false ;
  value.D1=this.DocumentRegisterTableForm.controls.D1.value;
  value.D2=this.DocumentRegisterTableForm.controls.D2.value;
  value.D3=this.DocumentRegisterTableForm.controls.D3.value;
  value.D4=this.DocumentRegisterTableForm.controls.D4.value;
  this.GetSortedDocumentsByRegisterID(value)
}

sortByItemAsc(){
  debugger
  let value=this.AddFileFormGroup.value;
  value.SortBy='item';
  value.SortAsc=true ;
  value.D1=this.DocumentRegisterTableForm.controls.D1.value;
  value.D2=this.DocumentRegisterTableForm.controls.D2.value;
  value.D3=this.DocumentRegisterTableForm.controls.D3.value;
  value.D4=this.DocumentRegisterTableForm.controls.D4.value;
  this.GetSortedDocumentsByRegisterID(value)
}
sortByItemDesc(){
  debugger
  let value=this.AddFileFormGroup.value;
  value.SortBy='item';
  value.SortAsc=false ;
  value.D1=this.DocumentRegisterTableForm.controls.D1.value;
  value.D2=this.DocumentRegisterTableForm.controls.D2.value;
  value.D3=this.DocumentRegisterTableForm.controls.D3.value;
  value.D4=this.DocumentRegisterTableForm.controls.D4.value;
  this.GetSortedDocumentsByRegisterID(value)
}
onChekboxChange(){
  let value=this.AddFileFormGroup.value;
  value.D1=this.DocumentRegisterTableForm.controls.D1.value;
  value.D2=this.DocumentRegisterTableForm.controls.D2.value;
  value.D3=this.DocumentRegisterTableForm.controls.D3.value;
  value.D4=this.DocumentRegisterTableForm.controls.D4.value;
  this.GetSortedDocumentsByRegisterID(value)
}
GetSortedDocumentsByRegisterID(value){
  this .itemArray.clear()
  this.UserService.GetSortedDocumentsByRegisterID(value).subscribe(resp=>{
    if(resp.status==Status.Success){
      this.documents=resp.data; 
      if(this.documents.length>0){
      // this .itemArray.clear()
        this.documents.forEach( (element) => {
          console.log(element.webstoreFile);
        this.setDate = this.datePipe.transform(element.date, 'dd/MM/yyyy');
        this.itemArray.push(this.formBuilder.group({
        DocId: [element.docId],
        DocRegId: [this.DocRegId],
        DocVer:[element.docVer],
        Name: [element.name],
        ViewValue: [element.viewValue],
        Rev: [element.rev],
        Item: [element.item],
        Link: [element.link],
        Date: [new Date(element.date)],
        Subject: [element.subject],
        Approved: [element.approved],
        ActualFileName:[element.acutalFileName],
        D1: [element.d1],
        D2: [element.d2],
        D3: [element.d3],
        D4: [element.d4],
        DocType:[element.docType],
        WebstoreFile:[element.webstoreFile],
          Icon: this.checkType(element.docType, element.acutalFileName)
        }))
      });
      }
      //this.checkType();
    }  
  })
}

GetIconValue(i){
  var Icon= this.DocumentRegisterTableForm.value.items[i].Icon;
   return Icon;
  }

  ViewLink(i){
    var url= this.DocumentRegisterTableForm.value.items[i].Link;
    window.open(url,"_blank");
    }

ViewFile(filename){
  let JobNO=this.AddFileFormGroup.controls.JobNo.value;
  window.open(this.endpointurl+"User/GetDocument?jobNo="+JobNO+"&filename="+filename,"_blank");
}

checkType(docType,name) {
  debugger
 // this.documents.forEach((document) => {
  let icon="";
      debugger;
      if(docType == 'F'){
        let type = name.split(".");
        let ext = type[type.length - 1];
        debugger;
        if (ext == "pdf") 
        icon = "assets/adobe.ico";
        else if ((ext == "dwg"))
          icon = "assets/autocad.ico";
        else if ((ext == "xls"))
          icon = "assets/spreadsheet.ico";
        else if ((ext == "doc"))
          icon = "assets/pen.ico";
        else if ((ext == "jpg"))
          icon = "assets/camera.ico";
        else if ((ext == "png"))
          icon = "assets/camera.ico";
        else if ((ext == "avi"))
          icon = "assets/movie.ico";
        else if ((ext == "wmv"))
          icon = "assets/movie.ico";
        else if ((ext == "mpg"))
          icon = "assets/movie.ico";
        else if ((ext == "mov"))
          icon = "assets/movie.ico";
        else if ((ext == "zip"))
          icon = "assets/zip.ico";
        else if ((ext == "skp"))
          icon = "assets/sketchup.ico";
        else if ((ext == "tml"))
          icon = "assets/sj.ico";
        else if ((ext == "3dm"))
          icon = "assets/rhino.ico";
        else if ((ext == "max"))
          icon = "assets/max.ico";
        else if ((ext == "dxf"))
          icon = "assets/dxficon.ico";
        else if ((ext == "dwf"))
          icon = "assets/dwf.ico";
        else if ((ext == "stp"))
          icon = "assets/Step.ico";
        else if ((ext == "stl"))
          icon = "assets/STLico.ico";
        else if ((ext == "xxx"))
          icon = "assets/xxx.ico";
        else if ((ext == "dwf"))
          icon = "assets/DWFIcon.ico";
        else if ((ext == "wfx"))
          icon = "assets/DWFXicon.ico";
        else if ((ext == "ico"))
          icon = "assets/I-icon.ico";
        else icon = "assets/qmark.ico";
      }
      else if(docType == 'L'){
        icon = "assets/link.ico";
      }
  //});
  debugger
  return icon;
}

GetWebStoreValue(i){
  var WebStore= this.DocumentRegisterTableForm.value.items[i].WebstoreFile;
   return WebStore;
  }

copyFolderPath(folderPath) {
  let txt =folderPath;
  let selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = txt;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  this.toastr.success("Folder Path copied to clipboard");
}

}
