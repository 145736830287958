import { Component, OnInit } from '@angular/core';
import { Status } from 'src/app/models/ResponseModel';

import { UserService } from 'src/app/services/user.service';
import { DatePipe } from '@angular/common';
import { CookiesService } from 'src/app/services/cookies.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  joblist: any=[];
  total: any;
  pageno:number=1;
  isAdmin: boolean = false;
  constructor(public datePipe :DatePipe,private UserService: UserService,private cookieService: CookiesService) { }

  ngOnInit() {
    let role = this.cookieService.getCookie('Role');
    if(role === 'Admin') this.isAdmin = true;
    this.GetLast10Jobs();
  }
  GetLast10Jobs()
  {
    var obj={
    pageno:this.pageno,
    pagesize:10
    };
    this.UserService.GetJobNumbersListSP(obj).subscribe(resp=>{
      if(resp.status==Status.Success){
        this.joblist=resp.data.item1; 
        this.total=resp.data.item2;
      }  
    })
  }
}
