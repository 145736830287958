import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookiesService } from './cookies.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  jwthelper : JwtHelperService;
  constructor(
    private router: Router,
    private cookiesService: CookiesService
  ) {
    this.jwthelper = new JwtHelperService();
   }

   GetUserData(){

      let token = localStorage.getItem('AuthToken');
      let decodedtoken = this.jwthelper.decodeToken(token);

      let userrecord = JSON.parse(decodedtoken.Userrecord);
      this.cookiesService.setCookie('UserName', userrecord.FirstName +" "+ userrecord.LastName);
      this.cookiesService.setCookie('UserSince', userrecord.DateCreated);
      this.cookiesService.setCookie('UserId', userrecord.Id);
      let roleid = userrecord.RoleId;
    console.log('token info', userrecord);
    if(roleid == 1){
      this.cookiesService.setCookie('Role','Admin');
    }
    else if(roleid == 2){
      this.cookiesService.setCookie('Role', 'User');
    }


    }


    IsUserLogin(){

      let token = localStorage.getItem('AuthToken');
      console.log('token: ', token);

      if(this.isTokenExpired() ||  (token == null || token == undefined || token == '')){
        localStorage.removeItem('AuthToken');
        localStorage.clear();
        // this.router.navigateByUrl('/login');
        return false;
      }
        return true;
    }

    isTokenExpired(){
      return this.jwthelper.isTokenExpired(localStorage.getItem('AuthToken'));
    }
}
