import { Component, OnInit } from "@angular/core";
import { CookiesService } from "src/app/services/cookies.service";
import { UserService } from "src/app/services/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { UserInfo } from "src/app/models/User";
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: "app-add-edit-user",
  templateUrl: "./add-edit-user.component.html",
  styleUrls: ["./add-edit-user.component.css"],
})
export class AddEditUserComponent implements OnInit {
  user = new UserInfo();
  userId;
  role;
  firstName: any;
  lastName: any;
  userName: any;
  securityCode: any;
  roleId:any;
  isActive: any;
  isAdmin:any;
  isUser:any;
  userForm: FormGroup;

  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private cookiesService: CookiesService,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.user["isActive"] = false;

    this.role = this.cookiesService.getCookie("Role");
    this.userId = this.route.snapshot.paramMap.get("id");
    console.log("user Id", this.userId);

    this.userForm = new FormGroup({
      firstName: new FormControl('',Validators.required),
      lastName: new FormControl('',Validators.required),
      userName: new FormControl('',Validators.required),
      securityCode: new FormControl('',Validators.required),
      RoleId: new FormControl('')
    })
  }

  ngOnInit() {
    
    if (this.userId != 0) this.getUserInfo();
  }
 

  getUserInfo() {
    // let userId = this.cookiesService.getCookie('UserId');
    // console.log('aaaa', userId)
    this.spinner.show();
    this.userService.getUserDetail(this.userId).subscribe((res) => {
      debugger;
      this.spinner.hide();
      console.log("reponse from get user info api", res);
      this.user = res.data;
      if(res.data.roleId==1){
        this.isAdmin=true;
        this.isUser=false;
      }
      else if(res.data.roleId==2){
        this.isAdmin=false;
        this.isUser=true;
      }
      // this.firstName = res.data.firstName;
      // this.lastName = res.data.lastName;
      // this.userName = res.data.userName;
      // this.securityCode = res.data.securityCode;
      // this.isActive = res.data.isActive;
      console.log('user', this.user)
    });
  }
  RadioButtonChange(v)
  {
     debugger;
      if(v==1)
      {
         this.roleId=1
      }
      else if(v==2)
      {
        this.roleId=2
      }
  }
  addEditUser() {
    debugger;
    let userId = this.cookiesService.getCookie("UserId");
    this.user["UpdatedBy"] = userId;
    if(this.roleId!=undefined && this.roleId!=null && this.roleId!="")
    {
      this.user["RoleId"]=this.roleId;

    }
    else if(this.isAdmin==true)
    {
      this.user["RoleId"]=1;
    }
    else if(this.isUser==true)
    {
      this.user["RoleId"]=2;
    }
    this.spinner.show();
    if(this.userId == 0) this.user.isActive = true;
    this.userService.addEditUser(this.user).subscribe((res) => {
      if (this.userId) {
        this.toastr.success("User details updated successfully");
      } else {
        this.toastr.success("User added successfully");
      }
      this.spinner.hide();
      this.router.navigateByUrl("/user-list");

      console.log("reposne from addedit user api", res);
    });
  }

  changeStatus() {
    this.user["isActive"] = !this.user["isActive"];
  }
  Back() {
    this.router.navigateByUrl("/user-list");
  }
}
