import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Status } from 'src/app/models/ResponseModel';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.css']
})
export class ContactDetailsComponent implements OnInit {
  pageNo: any;

  constructor(private spinner: NgxSpinnerService,private activeRoute:ActivatedRoute,private toastr: ToastrService, private UserService: UserService,private router :Router,private formBuilder:FormBuilder) { }
  contactlist: any=[];
  ContactListFormGroup: FormGroup;
  pageno:any;
  limit:number=10;
  total:any;
  Letter:any="";
  ngOnInit() {
    debugger
    this.ContactListForm();
    this.pageNo = this.activeRoute.snapshot.paramMap.get("page")
    if(this.pageNo!=null && this.pageNo!=0){
      this.pageno=parseInt(this.pageNo);
      this.GetContacts();
    }
    else{
      this.pageno=1;
      this.GetContacts();
    }
  }
  ContactListForm(): void {
    this.ContactListFormGroup = this.formBuilder.group({
      search: [''],
    })
  }
  GetContacts()
  {
    this.spinner.show();
    let value=this.ContactListFormGroup.value;
    value.pageno=this.pageno;
    value.pagesize=10;
        this.UserService.GetContactList(value).subscribe(resp=>{
      if(resp.status==Status.Success)
      {
        debugger
        this.contactlist=resp.data.list;
        this.total=resp.data.totalCount;//resp.data.length;
        this.spinner.hide();
      }
    })
  }
  SearchContactClick(){
    this.spinner.show();
    let value=this.ContactListFormGroup.value;
    this.pageno=1;
    value.pageno=this.pageno;
    value.pagesize=10;
    this.UserService.GetContactList(value).subscribe(resp=>{
      if(resp.status==Status.Success){
        this.contactlist=resp.data.list;
        this.total=resp.data.totalCount;
        this.spinner.hide();
      }  
    })
  }
  AddContactClick()
  {

    this.router.navigateByUrl("/addContact/0/0")
  }
  // EditContactClick(id)
  // {
  //   this.router.navigateByUrl("/addContact/"+id)
  // }
  DeleteContact(contactid) 
  {
    if(confirm("Are you sure to delete this contact ?")){
      this.UserService.DeleteContact(contactid).subscribe(resp => {

        if (resp.data == true) {
          this.toastr.success(resp.message);
          window.location.reload();
          //this.router.navigateByUrl("/contacts")
        }
        else {

        }
      });
    }
  }

  SearchbyLetter(letter,page){
    this.Letter=letter;
    this.pageno=page;
    this.spinner.show();
    let value=this.ContactListFormGroup.value;
    value.pageno=page;
    value.pagesize=10;
    value.Letter=letter;
    this.UserService.GetContactListbyLetter(value).subscribe(resp=>{
      if(resp.status==Status.Success){
        debugger
        this.contactlist=resp.data.item1; 
        this.total=resp.data.item2;
        this.spinner.hide();
      }  
    })
  }

  goToLink(url: string){
    debugger
    window.open(url, "_blank");
}
SetpageNO(pageno){   
  this.pageno=pageno;
   this.GetContacts();
}
goToPage(n: number): void {
  this.pageno = n;
  if(this.Letter!=""){
    this.SearchbyLetter(this.Letter,this.pageno);
  }
  else{
    this.GetContacts();
  }
 }
 onNext(): void {
   this.pageno++;
   if(this.Letter!=""){
    this.SearchbyLetter(this.Letter,this.pageno);
  }
  else{
    this.GetContacts();
  }
 }
 onPrev(): void {
   this.pageno--;
   if(this.Letter!=""){
    this.SearchbyLetter(this.Letter,this.pageno);
  }
  else{
    this.GetContacts();
  }  
 }
}
