import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../constants/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(public httpClient: HttpClient) { }

  getDocumentsForLink(linkCode: string): Observable<any>{
    return this.httpClient.get( Constants.apiUrl + 'User/GetDocumentLink?LinkCode=' + linkCode);
  }
  getDocument(jobNo, fileName):Observable<any>{
    return this.httpClient.get( Constants.apiUrl + 'User/GetDocument?jobNo=' + jobNo + '&fileName=' + fileName);
  }

  GetJobDetailForLink(code: string): Observable<any>{
    debugger;
    return this.httpClient.get( Constants.apiUrl + 'User/GetJobDetailForLink?linkCode=' + code);
  }

  GetJobDetailForProgressForm(progressCode): Observable<any>{
    debugger;
    return this.httpClient.get( Constants.apiUrl + 'Home/GetJobDetailForProgressForm?linkCode=' + progressCode);
  }
  GetDocumentsByJobNoForProgressForm(progressCode): Observable<any>{
    return this.httpClient.get( Constants.apiUrl + 'Home/GetDocumentsByJobNoForProgressForm?linkCode=' + progressCode);
  }
}
