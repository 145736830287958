import { Constants } from 'src/app/constants/constants';
import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { DocumentsService } from "src/app/services/documents.service";
import { NgxSpinnerService } from "ngx-spinner";
import { DocumentProgressForm } from "src/app/models/DocumentProgressForm";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UserService } from 'src/app/services/user.service';
import { Status } from 'src/app/models/ResponseModel';
import { Subject } from 'rxjs';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { CookiesService } from 'src/app/services/cookies.service';
import { DatePipe } from '@angular/common';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-progress-form',
  templateUrl: './progress-form.component.html',
  styleUrls: ['./progress-form.component.css']
})
export class ProgressFormComponent implements OnInit {
  dtOption: DataTables.Settings = {};
  headMessage : string;
  selectedRow : any;
  setClickedRow : Function;
  public documentList = new Array<DocumentProgressForm>();
  dtTrigger = new Subject();
  image: string;
  jobNo;
  jobTitle;
  entryDate;
  LastModified: any;
  DepartMentTableForm: FormGroup;
  public bsModalRef: BsModalRef;
  ActivityFormGroup: FormGroup;
  ItemsArray= [];
  DocId:any;
  DocDepartments:any;
  UserId=this.cookiesService.getCookie('UserId');
  ActivitiesArray= [];
  docVersion: any;
  itemName: any;
  progressCode:any;
  Deptlength: any;
  ProgressiveFormCode:any;
  
  constructor(private toastr: ToastrService,private route: ActivatedRoute,private documentsService: DocumentsService,
              private spinner: NgxSpinnerService,private UserService: UserService,private activeRoute:ActivatedRoute
              ,private formBuilder:FormBuilder,private cookiesService: CookiesService, private modalService: BsModalService
              ,public datePipe :DatePipe,private router :Router) 
  {
    this.selectedRow=0;
    this.progressCode = this.activeRoute.snapshot.paramMap.get("jobNo")
    this.ProgressiveFormCode=this.progressCode;
    this.progressCode=encodeURIComponent(this.progressCode);
    if(this.progressCode!=null){
      debugger
      this.GetJobDetail();
    }
    this.setClickedRow = function(index){
      debugger;
      this.selectedRow = index;
  }
  }

  ngOnInit() {
    this.dtOption = {
    "paging": false,
     "ordering": true,
    "info": false,
    "searching": false,
    "columnDefs": [
      { "orderable": false ,"targets": 6},
      { "orderable": false ,"targets": 7},
      { "orderable": false ,"targets": 9},
      // { "orderable": false ,"targets": 10},
      // { "orderable": false ,"targets": 11},
      // { "orderable": false ,"targets": 12},
      // { "orderable": false ,"targets": 13},
    ]
    }
    this.ActivityForm();
}

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ActivityForm(): void {
    this.ActivityFormGroup = this.formBuilder.group({
      ActivityText: [''],
      Status:[]
    })
  }

  GetJobDetail(){
    debugger
    this.documentsService.GetJobDetailForProgressForm(this.progressCode).subscribe((res) => {
      if (res.data)
       {
        this.jobNo = res.data.jobNo;
        this.jobTitle = res.data.jobTitle;
        this.entryDate = res.data.entryDate;
        this.LastModified=res.data.jobModifiedDate;
        if (res.data.imageFile != null && res.data.imageFile != '') {
            this.UserService.GetImageUrl(res.data.imageFile).subscribe(resp => {
              if (resp.status == Status.Success) {
                this.image = resp.data;
              }
            })
          }
        this.GetDocumentsByJobNoForProgressForm(this.progressCode);
      }
        else {
        this.toastr.warning("no data found");
        }
  });
  }
  GetDocumentsByJobNoForProgressForm(progressCode) 
  {
  this.spinner.show();
  this.documentsService.GetDocumentsByJobNoForProgressForm(progressCode).subscribe((res) => {
  this.spinner.hide();
  this.documentList = res.data;
  this.dtTrigger.next();
  this.checkType();
  });
  }

  Reset(progressCode)
  {
  this.dtTrigger = new Subject();
  this.spinner.show();
  this.documentsService.GetDocumentsByJobNoForProgressForm(progressCode).subscribe((res) => {
  this.spinner.hide();
  this.documentList = res.data;
  //this.dtTrigger.next();
  this.checkType();
  });
  }
  
reload(){
  debugger
  location.reload();
  //this.router.navigateByUrl('/progressForm/'+this.progressCode);
  
  // console.log(this.router.url);
  // this.router.routeReuseStrategy.shouldReuseRoute = function () {
  //   return false;
  // };
}

  gotoDocument(document) 
  {
  window.open(Constants.apiUrl +"User/GetDocument?jobNo="+document.jobNo+"&filename="+document.acutalFileName,"_blank");
  }
  
  checkType() 
  {
    this.documentList.forEach((document) => {
    if(document.docType == 'F'){
        let type = document.acutalFileName.split(".");
        let ext = type[type.length - 1];
        debugger;
        if (ext == "pdf") document.icon = "assets/adobe.ico";
        else if ((ext == "dwg"))
        document.icon = "assets/autocad.ico";
        else if ((ext == "xls"))
        document.icon = "assets/spreadsheet.ico";
        else if ((ext == "doc"))
        document.icon = "assets/pen.ico";
        else if ((ext == "jpg"))
        document.icon = "assets/camera.ico";
        else if ((ext == "png"))
        document.icon = "assets/camera.ico";
        else if ((ext == "avi"))
        document.icon = "assets/movie.ico";
        else if ((ext == "wmv"))
        document.icon = "assets/movie.ico";
        else if ((ext == "mpg"))
        document.icon = "assets/movie.ico";
        else if ((ext == "mov"))
        document.icon = "assets/movie.ico";
        else if ((ext == "zip"))
        document.icon = "assets/zip.ico";
        else if ((ext == "skp"))
        document.icon = "assets/sketchup.ico";
        else if ((ext == "tml"))
        document.icon = "assets/sj.ico";
        else if ((ext == "3dm"))
        document.icon = "assets/rhino.ico";
        else if ((ext == "max"))
        document.icon = "assets/max.ico";
        else if ((ext == "dxf"))
        document.icon = "assets/dxficon.ico";
        else if ((ext == "dwf"))
        document.icon = "assets/dwf.ico";
        else if ((ext == "dwf"))
        document.icon = "assets/dwf.ico";
        else if ((ext == "stp"))
        document.icon = "assets/Step.ico";
        else if ((ext == "stl"))
        document.icon = "assets/STLico.ico";
        else if ((ext == "xxx"))
        document.icon = "assets/xxx.ico";
        else if ((ext == "dwf"))
        document.icon = "assets/DWFIcon.ico";
        else if ((ext == "dwf"))
        document.icon = "assets/DWFIcon.ico";
        else if ((ext == "wfx"))
        document.icon = "assets/DWFXicon.ico";
        else if ((ext == "ico"))
        document.icon = "assets/I-document.icon.ico";
        else if ((ext == "docx"))
        document.icon = "assets/pen.ico";
        else document.icon = "assets/qmark.ico";
        }
        else if(document.docType == 'L'){
        document.icon = "assets/link.ico";
        }
    });
  }
  
  gotoLink(link){
    window.open(link,"_blank")
  }
  
  OpenLink(document)
  {
    var Link=Constants.baseUrl +"Docs/Job"+document.jobNo+"/"+document.acutalFileName;
    window.open(Link,"_blank")
  }

  get DepartmentArray()
  {
  return this.DepartMentTableForm.get('Departments') as FormArray
  }

  openActivityModal(ActivityTemp: TemplateRef<any>,document) 
  {
    this.DocId=document.docId;
    this.docVersion=document.docVer;
    this.itemName=document.item;
    this.GetDocumentDepartmentList(this.DocId);
    const config: ModalOptions = { class: 'modal-lg custom-position' };
     this.bsModalRef = this.modalService.show(ActivityTemp, config);
  }

  GetDocumentDepartmentList(DocId){
    this.UserService.GetDocumentDepartmentList(DocId).subscribe(resp=>{
      if(resp.status==Status.Success){
        this.ItemsArray=resp.data;
        var data=resp.data;
        this.Deptlength=resp.data.length;
        //console.log(this.selectedRow);
        this.GetDepartmentActivities(resp.data[this.selectedRow]);
        //this.selectedRow=0;
        // this.UserService.GetDepartmentActivities(resp.data[0].documentDepartmentId).subscribe(resp=>{
        //   if(resp.status==Status.Success){
        //     this.ActivitiesArray=resp.data;
        //   }
        // })
      }
    })
  }

  GetDepartmentActivities(item){
    this.DocDepartments=item;
    let statusValue=this.GetStatusValueUsingText(item.statusName);
    this.ActivityFormGroup.get('Status').setValue(statusValue);
    this.UserService.GetDepartmentActivities(item.documentDepartmentId).subscribe(resp=>{
      if(resp.status==Status.Success){
        this.ActivitiesArray=resp.data;
      }
    })
  }

  SaveActivity(){
    let value=this.ActivityFormGroup.value;
     value.DocumentDepartmentId=this.DocDepartments.documentDepartmentId;
     value.JobDepartmentId=this.DocDepartments.jobDepartmentId;
     value.DocId=this.DocId;
     value.ActivityBy=this.UserId;
    this.UserService.SaveActivity(value).subscribe(resp=>{
      if(resp.status==Status.Success){
        this.toastr.success(resp.message);
        this.GetDocumentDepartmentList(this.DocId)
        this.ActivityFormGroup.reset();
      }
    })
  }

  GetStatusValueUsingText(textStatus){
    if(textStatus=="No Entry Made "){
      return 1;
    }
    else if(textStatus=="Nothing To Do"){
      return 2;
    }
    else if(textStatus=="Finished"){
      return 3;
    }
    else if(textStatus=="Unfinished"){
      return 4;
    }
  }
}
