import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesService } from 'src/app/services/cookies.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  userName: string;
  memberSince;
  constructor(
    private router: Router,
    private cookieService: CookiesService
  ) { }

  ngOnInit() {
    this.userName = this.cookieService.getCookie('UserName');
    this.memberSince = this.cookieService.getCookie('UserSince');
  }

  logout(){
    localStorage.clear();
    this.cookieService.deleteCookies();
    this.router.navigateByUrl('/login');
  }

}
